import React from "react";
import { Link } from "react-router-dom";
import "./style/buyNowLandingPage.css";
import BookImage from "../../../assets/buynowlandingpage/PCOD_Healing_Guide-1024x1024.webp";
import ArrowTransparent from "../../../assets/buynowlandingpage/bluearrows1-transparent-1-2.gif";
import LeftArrow from "../../../assets/buynowlandingpage/arrow-left-1-1.png";
import RightArrow from "../../../assets/buynowlandingpage/arrow-right-1-1.png";
import Understanding from "../../../assets/buynowlandingpage/understanding.svg";
import Ayurveda from "../../../assets/buynowlandingpage/ayurveda.svg";
import Lifestyle from "../../../assets/buynowlandingpage/lifestyle.svg";
import Detoxification from "../../../assets/buynowlandingpage/detoxification.svg";
import { FaCheck, FaUnlock } from "react-icons/fa";
import Testimonial1 from "../../../assets/buynowlandingpage/feedback-1.jpg";
import Testimonial2 from "../../../assets/buynowlandingpage/feedback-2.jpg";
import Testimonial3 from "../../../assets/buynowlandingpage/feedback-3.jpg";
import { Helmet } from "react-helmet";

const BuyNowLandingPage = () => {
  return (
    <>
      <Helmet>
        {/* Meta Pixel Code */}
        <script>
          {`
   !function(f,b,e,v,n,t,s)
   {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
   n.callMethod.apply(n,arguments):n.queue.push(arguments)};
   if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
   n.queue=[];t=b.createElement(e);t.async=!0;
   t.src=v;s=b.getElementsByTagName(e)[0];
   s.parentNode.insertBefore(t,s)}(window, document,'script',
   'https://connect.facebook.net/en_US/fbevents.js');
   fbq('init', '335594925825705');
   fbq('track', 'ViewContent');
  `}
        </script>
        <noscript>
          {`
          <img
          height="1"
          width="1"
          style="display:none"
          src="https://www.facebook.com/tr?id=335594925825705&ev=PageView&noscript=1"
        />
          `}  
        </noscript>
        {/* End Meta Pixel Code */}
      </Helmet>
      <section className="buy-now-landing-page-main">
        <div className="line-up"></div>
        <div className="container container-buy-now">
          <div className="buy-now-heading">
            <h1>
              Get Your Ultimate Guide To Heal
              <br /> Your PCOD Naturally At Just ₹19/-
            </h1>
            <h2>
              "Unlock Ayurveda's Hidden Secrets To Heal Your PCOD Challenges
              Naturally"
            </h2>
          </div>
          <div className="buy-now-book-content d-flex justify-content-center align-items-center">
            <div className="book-image-column">
              <img src={BookImage} alt="BookImage" />
            </div>
            <div className="book-animation-column">
              <img src={ArrowTransparent} alt="BookImage" />
            </div>
            <div className="book-content-column">
              <h3>
                Get Your Ebook and Start Your Journey Towards Natural PCOD
                Healing
              </h3>
              <p>
                This Comprehensive Resource Unlocks Ayurveda's Hidden Secrets To
                Address Your PCOD Challenges. Say Goodbye To The Struggles And
                Discomfort Of PCOD And Explore Effective Solutions, Gain
                Valuable Insights, And Embrace A Healthier, More Balanced Life.
              </p>
              <Link className="access-button-link">
                <button className="btn btn-get-access">
                  Get Access Now At Just Rs19!
                </button>
              </Link>
            </div>
          </div>
          <div className="start-your-pcod-button-container">
            <div className="left-arrow">
              <img src={RightArrow} alt="..." />
            </div>
            <div className="button btn-middle-pcod">
              <Link className="button-pcod-link">
                <button className="btn btn-middle-pcod-button">
                  Start Your PCOD Healing Journey Today! <br />
                  <span className="btn-middle-pcod-second-text">
                    For Just ₹19 Today!s
                  </span>
                </button>
              </Link>
            </div>
            <div className="right-arrow">
              <img src={LeftArrow} alt="..." />
            </div>
          </div>
          <div className="book-card-container">
            <div className="book-card-container-heading">
              <h2 className="heading-1">With This E-Book</h2>
              <h2 className="heading-2">You Will Get</h2>
            </div>
            <div className="card-container-content">
              <div className="card card-container-content-box">
                <div className="card-container-box-header">
                  <div className="card-container-image-link">
                    <img src={Understanding} alt="..." />
                  </div>
                  <h2 className="card-heading">Understanding PCOD</h2>
                </div>
                <div className="card-container-box-content">
                  <p>
                    Understand the root causes, symptoms, physical and emotional
                    impact that PCOD can have on women's lives. From hormonal
                    imbalances to its effects on fertility and emotional
                    well-being, you get a well-rounded perspective on PCOD.
                  </p>
                </div>
              </div>
              <div className="card card-container-content-box">
                <div className="card-container-box-header">
                  <div className="card-container-image-link">
                    <img src={Ayurveda} alt="..." />
                  </div>
                  <h2 className="card-heading">Ayurveda and PCOD</h2>
                </div>
                <div className="card-container-box-content">
                  <p>
                    You will discover the synergies between Ayurveda and PCOD
                    management, gaining insights into how this natural healing
                    system can offer personalized solutions for your specific
                    needs.From restoring balance in the body to the significance
                    of individual constitutions,You will gain a deeper
                    understanding of Ayurvedic principles and their relevance in
                    the context of PCOD
                  </p>
                </div>
              </div>
              <div className="card card-container-content-box">
                <div className="card-container-box-header">
                  <div className="card-container-image-link">
                    <img src={Lifestyle} alt="..." />
                  </div>
                  <h2 className="card-heading">
                    Lifestyle and PCOD Management
                  </h2>
                </div>
                <div className="card-container-box-content">
                  <p>
                    Understand the role that daily habits, exercise, and stress
                    management play in alleviating PCOD symptoms and restoring
                    balance. Learn how personalized lifestyle changes can
                    positively impact your overall well-being and contribute to
                    PCOD management.
                  </p>
                </div>
              </div>
              <div className="card card-container-content-box">
                <div className="card-container-box-header">
                  <div className="card-container-image-link">
                    <img src={Detoxification} alt="..." />
                  </div>
                  <h2 className="card-heading">Detoxification and PCOD</h2>
                </div>
                <div className="card-container-box-content">
                  <p>
                    Explores the significance of cleansing the system to remove
                    toxins and balance hormonal levels, which are often
                    disrupted in individuals with PCOD. You will gain insights
                    into Ayurvedic detoxification methods, herbal remedies, and
                    dietary changes that support the body's natural detox
                    processes.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="ebook-list-container">
            <div className="ebook-list-container-heading">
              <h2 className="ebook-list-heading">
                Get Your Ebook And Start Your Journey Towards Natural PCOD
                Healing
              </h2>
            </div>
            <div className="ebook-list-content-box">
              <ul className="ebook-list-group">
                <li className="ebook-list-group-item">
                  <span className="ebook-list-group-icon">
                    <FaCheck />
                  </span>
                  <span className="ebook-list-group-text">
                    Gain a deep understanding of PCOD's root causes
                  </span>
                </li>
                <li className="ebook-list-group-item">
                  <span className="ebook-list-group-icon">
                    <FaCheck />
                  </span>
                  <span className="ebook-list-group-text">
                    Unlock the powerful synergy between Ayurveda and PCOD
                    management.
                  </span>
                </li>
                <li className="ebook-list-group-item">
                  <span className="ebook-list-group-icon">
                    <FaCheck />
                  </span>
                  <span className="ebook-list-group-text">
                    Learn Ayurvedic principles tailored specifically to tackle
                    PCOD.
                  </span>
                </li>
                <li className="ebook-list-group-item">
                  <span className="ebook-list-group-icon">
                    <FaCheck />
                  </span>
                  <span className="ebook-list-group-text">
                    Discover personalized Ayurvedic diet plans for effective
                    PCOD control.
                  </span>
                </li>
                <li className="ebook-list-group-item">
                  <span className="ebook-list-group-icon">
                    <FaCheck />
                  </span>
                  <span className="ebook-list-group-text">
                    Explore detoxification methods designed to alleviate
                    PCOS-related issues.
                  </span>
                </li>
                <li className="ebook-list-group-item">
                  <span className="ebook-list-group-icon">
                    <FaCheck />
                  </span>
                  <span className="ebook-list-group-text">
                    Implement actionable Ayurvedic solutions to relieve PCOD
                    discomfort
                  </span>
                </li>
                <li className="ebook-list-group-item">
                  <span className="ebook-list-group-icon">
                    <FaCheck />
                  </span>
                  <span className="ebook-list-group-text">
                    Enhance your quality of life with practical lifestyle
                    adjustments.
                  </span>
                </li>
              </ul>
            </div>
            <div className="ebook-list-footer-content">
              <h2 className="ebook-list-footer-text">And Much More...</h2>
              <div className="ebook-list-footer-button">
                <Link className="ebook-list-footer-link">
                  <button className="btn btn-ebook-list-footer">
                    Start Your PCOD Healing Journey Today! <br />{" "}
                    <span className="btn-ebook-list-footer-second-text">
                      For Just ₹19 Today!s
                    </span>
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="container-testimonial">
            <div className="container-testimonial-heading">
              <h2>Have A Look What Others Have To Say</h2>
            </div>
            <div className="testimonial-image-content">
              <div className="testimonial-image">
                <img
                  src={Testimonial1}
                  alt="Testimonial1"
                  className="testimonial-image-review"
                />
              </div>
              <div className="testimonial-image">
                <img
                  src={Testimonial2}
                  alt="Testimonial2"
                  className="testimonial-image-review"
                />
              </div>
              <div className="testimonial-image">
                <img
                  src={Testimonial3}
                  alt="Testimonial3"
                  className="testimonial-image-review"
                />
              </div>
            </div>
            <div className="book-image">
              <img src={BookImage} alt="BookImage" />
            </div>
            <div className="testimonial-button">
              <Link className="testimonial-button-link">
                <button className="btn btn-testimonial-button">
                  Start Your PCOD Healing Journey Today! <br />
                  <span className="btn-testimonial-button-second-text">
                    For Just ₹19 Today!s
                  </span>
                </button>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <footer className="buynow-footer">
        <div className="buynow-footer-container container">
          <div className="buynow-price-column">
            <div className="price-content">
              <h2 className="price">
                <span className="discount-price">₹ 19</span>
                <span className="regular-price">₹ 499</span>
              </h2>
            </div>
            <div className="get-instant-access-content">
              <FaUnlock />
              <span className="get-instant-access-text">
                Get Instant Access Right Now
              </span>
            </div>
          </div>
          <div className="buy-now-buttom-column">
            <Link className="buy-now-btn-link">
              <button className="btn btn-buy-now-button">Buy Now</button>
            </Link>
          </div>
        </div>
      </footer>
    </>
  );
};

export default BuyNowLandingPage;
