import React, { useEffect } from "react";
import "./style/courses.css";
import Image1 from "../../../assets/ayurvedic-fertilaty.png";
import Image2 from "../../../assets/female-fertility.png";
import Image3 from "../../../assets/garbhasanskar.png";
import { Link } from "react-router-dom";
import Aos from "aos";
import 'aos/dist/aos.css';
// import { FaAngleRight } from "react-icons/fa6";

const Courses = () => {
    useEffect(() => {
        Aos.init({
          duration: 2000,
          offset: 100,
        });
      }, []);
  return (
    <section className="coursesSection d-flex justify-content-center align-item-center">
      <div className="container courses-container d-flex flex-column justify-content-center align-items-center ">
        {/* <div className="course-container-heading">
          <h1 className="course-heading" data-aos="fade-right" data-aos-easing="ease">Courses</h1>
        </div> */}
        <div className="course-card-container d-flex justify-content-center align-items-center">
          <div className="course-card card">
            <img src={Image1} alt="" />
            <div className="course-card-content">
              <div className="course-content">
                <h3>Basic of Ayurved Fertility & treatments</h3>
                <p>A three month certificate course .</p>
                <Link to='/courses'>
                  <button className="btn btn-learnMore">Know More</button>
                </Link>
              </div>
            </div>
          </div>
          <div className="course-card card">
            <img src={Image2} alt="" />
            <div className="course-card-content">
              <div className="course-content">
                <h3>Diploma in ayurved Gynaecological diseases & Infertility management</h3>
                <p>A six month diploma course to make you expert. </p>
                <Link to='/courses'>
                  <button className="btn btn-learnMore">Know More</button>
                </Link>
              </div>
            </div>
          </div>
          <div className="course-card card">
            <img src={Image3} alt="" />
            <div className="course-card-content">
              <div className="course-content">
                <h3>Garbhasanskar Trainer</h3>
                <p>A six month diploma course to make you expert. </p>
                <Link to='/courses'>
                  <button className="btn btn-learnMore">Know More</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="seeAllButton">
        <Link>
                  <button className="btn btn-seeAll">See All <FaAngleRight /> </button>
      </Link>
      </div>  */}
      </div>
    </section>
  );
};

export default Courses;
