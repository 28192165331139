import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import './style/facilitiesAndService.css';
import OnlineClass from '../../../assets/online-class-1.svg';
import RecordedLecture from '../../../assets/recorded-lecture.svg';
import Pdf from '../../../assets/pdf-file.svg';
import MultipleCourses from '../../../assets/multiple-course.svg';
import AffordablePrice from '../../../assets/online-document.svg';
import Certificate from '../../../assets/certificate.svg';
const FacilitiesAndService = () => {
    useEffect(()=>{
        Aos.init({
            duration: 2000,
            offset: 100
        })
    },[]);
  return (
    <section className='facilitiesAndService d-flex
    justify-content-center align-items-center'>
     <div className="container facilities-and-service-container d-flex flex-column
    justify-content-center align-items-center">
        <div className="container-heading">
            <h1 className='h1' data-aos="fade-right">Facilities & Services</h1>
        </div>
        <div className="facilities-and-service-content-container d-flex flex-column justify-content-center align-items-center">
            <div className="content-container-row">
                <div className="content-box">
                    <div className="svg-image">
                     <img src={OnlineClass} alt="OnlineClass" style={{'width': '65px'}}/>
                    </div>
                    <h4>Online Lecture</h4>
                </div>
                <div className="content-box">
                    <div className="svg-image">
                     <img src={RecordedLecture} alt="RecordedLecture" />
                    </div>
                    <h4>Recorded Lecture</h4>
                </div>
                <div className="content-box">
                    <div className="svg-image">
                     <img src={Pdf} alt="Pdf" />
                    </div>
                    <h4>Notes in pdf format</h4>
                </div>
                <div className="content-box">
                    <div className="svg-image">
                     <img src={MultipleCourses} alt="MultipleCourses" />
                    </div>
                    <h4>Multiple Courses</h4>
                </div>
            </div>
            <div className="content-container-row">
            <div className="content-box">
                    <div className="svg-image">
                     <img src={AffordablePrice} alt="AffordablePrice" />
                    </div>
                    <h4>Affordable Price</h4>
                </div>
                <div className="content-box">
                    <div className="svg-image" style={{'lineHeight': 5.3}}>
                     <img src={Certificate} alt="Certificate" style={{'width': '55px'}}/>
                    </div>
                    <h4>Certification</h4>
                </div>
            </div>
        </div>
     </div>
    </section>
  )
}

export default FacilitiesAndService;
