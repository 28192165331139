import React,{useEffect} from 'react';
import './style/textSection.css';
import Aos from 'aos';

const TextSection = () => {
  useEffect(()=>{
    Aos.init({
      duration: 2000,
      offset: 100
    });
  },[])
  return (
    <section className='TextSection'>
        <div className="text-section-container container d-flex justify-content-center align-items-center flex-column">
            <div className="text-container-content" data-aos="fade-up" data-aos-duration="3000" data-aos-easig="ease">
                <p>
                At Tanwish Institute of Health and Wellness, we are dedicated to advancing the ancient wisdom of Ayurveda and empowering the future of Ayurvedic medicine. Established with a profound commitment to quality education, our institute is a beacon for aspiring Ayurvedic doctors seeking comprehensive training in various specialties.
                </p>
            </div>
            <div className="mission-conatiner-content">
                <h4>Our Mission:</h4>
                <p>Our mission is simple yet profound: to nurture and guide individuals on their journey towards becoming skilled Ayurvedic practitioners. We believe that Ayurveda holds the key to holistic well-being, and our goal is to equip our students with the knowledge and expertise needed to make a meaningful impact on the health and lives of others.</p>
            </div>
        </div>
    </section>
  )
}

export default TextSection;
