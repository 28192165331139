import React, { useEffect } from "react";
import "./style/heroSection.css";
// import WaveImage from "../../../assets/wave-svg-1.svg";
// import FlowerImage1 from "../../../assets/flower-image-3.png";
// import FlowerImage2 from "../../../assets/flower-image-4.png";
import Aos from "aos";
import "aos/dist/aos.css";

const HeroSection = () => {
  useEffect(() => {
    Aos.init({
      duration: 2000,
      offset: 100,
    });
  }, []);
  return (
    <section className="heroSectionAbout d-flex justify-content-center align-items-center">
      <div className="container-about d-flex justify-content-center align-items-center">
        {/* <div
          className="about-flower-image-1"
          data-aos="zoom-in-right"
          data-aos-easing="ease"
        >
          <img src={FlowerImage1} alt="...." />
        </div> */}
        <div className="container container-heading-box-about d-flex justify-content-center align-items-center text-center">
          <h1 data-aos="zoom-in-up" data-aos-easing="ease">
            Welcome to
            <br />
            Tanwish Institute of Health and Wellness
          </h1>
        </div>
        {/* <div
          className="about-flower-image-2"
          data-aos="zoom-in-left"
          data-aos-easing="ease"
        >
          <img src={FlowerImage2} alt="...." />
        </div> */}
      </div>
      {/* <div className="wave-line-about">
        <img src={WaveImage} alt="..." />
      </div> */}
    </section>
  );
};

export default HeroSection;
