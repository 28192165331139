import React, { useEffect, useState } from 'react';
import './style/contactMain.css';
import FlowerImage from '../../assets/flower-image-2.png';
import Aos from "aos";
import 'aos/dist/aos.css';

const ContactMain = () => {
    
    const [phoneNumber, setPhoneNumber] = useState("");
    const handlePhoneNumberChange = (e) => {
      const inputValue = e.target.value;
      // Use a regular expression to allow only numeric digits
      const numericValue = inputValue.replace(/\D/g, "");
      setPhoneNumber(numericValue);
    };

    useEffect(()=>{
        Aos.init({
          duration: 2000,
          offset: 100,
        });
      },[]);

  return (
    <section className='contactMain d-flex justify-content-center align-items-center flex-column'>
        <div className="container contact-main-container d-flex justify-content-start">
         <div className="contact-main-heading">
            <h1 data-aos='fade-right' data-aos-easing='ease'>Get in touch for any Enquiries </h1>
            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean tempor<br /> augue orci, in imperdiet leo dapibus sit amet.</p> */}
         </div>
        </div>
        <div className="container container-contact-main-content d-flex justify-content-center align-items-start">
        <div className="main-form-column">
          <div className="main-form-column-heading">
            <h1 className="h1">Contact Us</h1>
            {/* <h5 className="h5">Contact for any of your enquiries </h5> */}
          </div>
          <div className="main-form-box">
            <form action="">
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                placeholder="Name"
              />
               <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                placeholder="Email Address"
              />
             <div className="form-group">
                <input
                  type="tel"
                  name="phone"
                  placeholder="Phone Number"
                  id="phone"
                  className="form-control"
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                 required/>
                {phoneNumber.length > 0 && !/^\d{10}$/.test(phoneNumber) && (
                  <p style={{ color: "red", fontSize: '13px', fontWeight: 500}} className='error-msg' >
                    Please enter a valid 10-digit phone number.
                  </p>
                )}
              </div>
               {/* <PhoneInput
                inputProps={{
                    type: 'tel',
                    id:'phone',
                    name: 'phone',
                    className:'form-control phone-input',
                    required: true,
                    autoFocus: true
                  }}
                  country={'in'}
                  placeholder={'Phone Number'} 
                //   disableCountryCode={true}  
              /> */}
               <input
                type="text"
                className="form-control"
                id="message"
                name="message"
                placeholder="Message"
              />
              <button type="submit" className="btn btn-submit" name="submit" id="formSubmit">Submit Form</button>
            </form>
          </div>
        </div>
        <div className="main-flower-image" data-aos="zoom-in-right">
            <img src={FlowerImage} alt="..." />
        </div>
        <div className="main-image-column"></div>
        </div>
    </section>
  )
}

export default ContactMain
