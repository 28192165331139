import React, { useState, useEffect } from "react";
import Lottie from "lottie-react";
import "./style/thankYouWeightGain.css";
import AnimatioData from "../../../../assets/Lottie-Animation/Check-animation.json";
import { Helmet } from "react-helmet";

const ThankYouWeightGain = () => {
  const [animationStyle, setAnimationStyle] = useState({
    width: "200px",
    height: "200px",
  });

  useEffect(() => {
    // Define breakpoints for your media queries
    const breakpoints = {
      small: 576,
      medium: 768,
    };

    // Update the animationStyle based on the screen width
    const updateAnimationStyle = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < breakpoints.small) {
        setAnimationStyle({
          width: "100px",
          height: "100px",
        });
      } else {
        setAnimationStyle({
          width: "200px",
          height: "200px",
        });
      }
    };
    updateAnimationStyle();
    window.addEventListener("resize", updateAnimationStyle);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", updateAnimationStyle);
    };
  }, []);

  return (
    <>
    <Helmet>
      {/* Meta Pixel Code */}
      <script>
        {`
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '335594925825705');
    fbq('track', 'Purchase');
  `}
      </script>
      <noscript>
        {`
         <img
         height="1"
         width="1"
         style="display:none"
         src="https://www.facebook.com/tr?id=335594925825705&ev=PageView&noscript=1"
       />
        `}
        
      </noscript>
      {/* End Meta Pixel Code  */}
    </Helmet>
    <div className="thankYouPageWeightGain">
      <div className="container container-thank-you-weight-gain">
        <Lottie
          animationData={AnimatioData}
          loop={true}
          autoPlay={true}
          style={animationStyle}
        />
        <h1>
          <span className="color-change-green">Congratulations</span> For
          Getting your Weight Gain Secrets EBook
        </h1>
        <div className="email-download-container-box">
          <h1>Please Check Your Email to Download Your Guide</h1>
        </div>
      </div>
    </div>
    </>
  );
};

export default ThankYouWeightGain;
