import React, { useEffect, useState } from "react";
import "./style/contactForm.css";
import FlowerImage from "../../../assets/flower-image-2.png";
import Aos from "aos";
import "aos/dist/aos.css";

const ContactForm = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const handlePhoneNumberChange = (e) => {
    const inputValue = e.target.value;
    // Use a regular expression to allow only numeric digits
    const numericValue = inputValue.replace(/\D/g, "");
    setPhoneNumber(numericValue);
  };

  useEffect(() => {
    Aos.init({
      duration: 2000,
      offset: 100,
    });
  }, []);
  return (
    <section className="contactForm d-flex justify-content-center align-items-center">
      <div className="container contact-form-container d-flex justify-content-center align-items-start">
        <div className="form-column">
          <div className="form-column-heading">
            <h1 className="h1">Contact Us</h1>
            <h5 className="h5">Contact for any of your enquiries</h5>
          </div>
          <div className="form-box">
            <form action="">
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                placeholder="Name"
                required
              />
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                placeholder="Email Address"
                required
              />
              <div className="form-group">
                <input
                  type="tel"
                  name="phone"
                  placeholder="Phone Number"
                  id="phone"
                  className="form-control"
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                 required/>
                {phoneNumber.length > 0 && !/^\d{10}$/.test(phoneNumber) && (
                  <p style={{ color: "red", fontSize: '13px', fontWeight: 500 }}>
                    Please enter a valid 10-digit phone number.
                  </p>
                )}
              </div>
              {/* <PhoneInput
                inputProps={{
                    type: 'tel',
                    id:'phone',
                    name: 'phone',
                    className:'form-control phone-input',
                    required: true,
                    autoFocus: true
                  }}
                  country={'in'}
                  placeholder={'Phone Number'} 
                  // disableCountryCode={true}
                
              /> */}
              <input
                type="text"
                className="form-control"
                id="message"
                name="message"
                placeholder="Message"
                required
              />
              <button
                type="submit"
                className="btn btn-submit"
                name="submit"
                id="formSubmit"
              >
                Submit Form
              </button>
            </form>
          </div>
        </div>
        <div className="flower-image" data-aos="zoom-in-right">
          <img src={FlowerImage} alt="..." />
        </div>
        <div className="image-column"></div>
      </div>
    </section>
  );
};

export default ContactForm;
