import React, { useEffect } from 'react'
import HeroSection from '../components/courses/hero-section/HeroSection'
import MainSection from '../components/courses/main-section/MainSection'
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';

const Courses = () => {
  useEffect (()=>{
    window.scrollTo(0,0);
  }, []);
  return (
    <>
     <Header />
     <HeroSection />
     <MainSection />
     <Footer />
    </>

  )
}

export default Courses
