import React, { useEffect } from 'react'
import PrivacyPolicyPage from '../components/privacy-policy/PrivacyPolicyPage'
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';

const PrivacyPolicy = () => {
  useEffect (()=>{
    window.scrollTo(0,0);
  }, []);
  return (
   <>
     <Header />
    <PrivacyPolicyPage />
    <Footer />
   </>
  )
}

export default PrivacyPolicy
