import React from "react";
import Logo1 from "../../assets/logo-tanvish-white.png";
import "./style/footer.css";
import { Link } from "react-router-dom";
import {
  FaInstagram,
  FaTwitter,
  FaYoutube,
  FaLinkedinIn,
} from "react-icons/fa";
import { BiLogoFacebookSquare } from "react-icons/bi";

const Footer = () => {
  return (
    <footer className="footer d-flex justify-content-center align-items-center flex-column">
      <div className="container container-footer">
        <div className="container-footer-upper-part">
          <div className="footer-first-column">
            <div className="footer-logo">
              <Link to="/">
                <img src={Logo1} alt="Logo" />
              </Link>
            </div>
            {/* <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
            tempor augue orci,
          </p> */}
          </div>
          <div className="footer-second-column">
            <h5>Tanwish institute </h5>
            <div className="quick-link-list">
              <ul className="list-group">
                <li className="list-group-item">
                  <Link to="/" className="quick-link-text">
                    Home
                  </Link>
                </li>
                <li className="list-group-item">
                  <Link to="/courses" className="quick-link-text">
                    Courses
                  </Link>
                </li>
                <li className="list-group-item">
                  <Link to="/about-us" className="quick-link-text">
                    About Us
                  </Link>
                </li>
                {/* <li className="list-group-item">
                <Link to="/" className="quick-link-text">Blog </Link>
              </li> */}
                <li className="list-group-item">
                  <Link to="/contact-us" className="quick-link-text">
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-third-column">
            <h5>Courses</h5>
            <div className="quick-link-list">
              <ul className="list-group">
                <li className="list-group-item">
                  <Link to="/" className="quick-link-text">
                    Online courses
                  </Link>
                </li>
                <li className="list-group-item">
                  <Link to="/courses" className="quick-link-text">
                    Recorded Lectures
                  </Link>
                </li>
                <li className="list-group-item">
                  <Link to="/about-us" className="quick-link-text">
                    Affordable Prices
                  </Link>
                </li>
                <li className="list-group-item">
                  <Link to="/" className="quick-link-text">
                    Notes in pdf
                  </Link>
                </li>
                <li className="list-group-item">
                  <Link to="/contact-us" className="quick-link-text">
                    Certification
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-fourth-column">
            <h5>Contact Us</h5>
            <div className="social-links">
              <div className="soical-link-item">
                <Link
                  to="https://instagram.com/tanwishinstitute?igshid=MzRlODBiNWFlZA=="
                  className="icon-link"
                >
                  <FaInstagram />
                </Link>
              </div>
              <div className="soical-link-item">
                <Link to="" className="icon-link">
                  <BiLogoFacebookSquare />
                </Link>
              </div>
              <div className="soical-link-item">
                <Link
                  to="https://www.linkedin.com/in/tanwish-institute-988b38294?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                  className="icon-link"
                >
                  <FaLinkedinIn />
                </Link>
              </div>
              <div className="soical-link-item">
                <Link
                  to="https://x.com/Tanwishinst?t=0ao8STmPaDaiOZDdxISmfg&s=35"
                  className="icon-link"
                >
                  <FaTwitter />
                </Link>
              </div>
              <div className="soical-link-icon">
                <Link
                  to="https://www.youtube.com/@TanwishInstitute"
                  className="icon-link"
                >
                  <FaYoutube />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="divide-line"></div>
      </div>
      <div className="container container-footer-last">
        <div className="first-column-last">
          <ul className="last-list-group">
            <li className="last-list-group-item">
              <Link to="https://merchant.razorpay.com/policy/N3uCqIlSI1PVUn/privacy">Privacy Policy</Link>
            </li>
            <li className="last-list-group-item">
              <Link to="https://merchant.razorpay.com/policy/N3uCqIlSI1PVUn/terms">Terms & condition</Link>
            </li>
            <li className="last-list-group-item">
              <Link to="https://merchant.razorpay.com/policy/N3uCqIlSI1PVUn/refund">Refund Policy</Link>
            </li>
          </ul>
        </div>
        <div className="second-column-last">
          <div className="copyright-column">
            <p>
              ©{new Date().getFullYear()}, All Rights Reserved design by{" "}
              <Link to="https://www.procohat.com/">ProCohat</Link>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
