import React from "react";
import "./style/mainSection.css";
import Image1 from "../../../assets/ayurvedic-fertilaty.png";
import Image2 from "../../../assets/female-fertility.png";
import Image3 from "../../../assets/garbhasanskar.png";
import dermatology from "../../../assets/dermatology.webp"
import orthopedic from "../../../assets/orthopedic.webp"
import gynaeocology from "../../../assets/ayurved-gynaecology.webp"
import autoimmune from "../../../assets/autoimmune.webp"
import neurology from "../../../assets/neurology.webp"
import psychiatry from "../../../assets/psychiatry.webp"
import malesexualhealth from "../../../assets/male-sexual-health.webp"
import AyurvedicDieteticsNutrition from "../../../assets/AyurvedicDietetics&Nutrition.webp"
import { Link } from "react-router-dom";

const MainSection = () => {
  return (
    <section className="main-section-course pt-5 pb-5 d-flex justify-content-center alig-items-center">
      <div className="container container-course-main-section d-flex justify-content-center alig-items-center flex-column">
        <div className="container-course-main-section-row">
          <div className="card card-course-main">
            <img src={Image1} alt="Image1" />
            <div className="main-card-content">
              <h5>Basic of Ayurved Fertility & treatments</h5>
              {/* <p>A 3 month certificate course .</p> */}
              {/* <ul className="list-course-card">
                <li className="list-course-card-item">5 week course</li>
                <li className="list-course-card-item">4 hours Per week</li>
                <li className="list-course-card-item">
                  Case studies on male & female fertility
                </li>
                <li className="list-course-card-item">
                  Protocol of patient management in fertility
                </li>
                <li className="list-course-card-item">
                  Lifetime support in fertlity cases
                </li>
                <li className="list-course-card-item">Expert guest lecture</li>
                <li className="list-course-card-item">
                  {" "}
                  Weekly doubt solving session
                </li>
                <li className="list-course-card-item">
                  Completly online course
                </li>
                <li className="list-course-card-item">
                  Ug, pg students can join
                </li>
              </ul> */}
              <div className="button-buyNow" style={{marginTop : '70px'}}>
                <Link
                  to="https://tanwishinstitute.graphy.com/courses/Basic-of-Ayurved-Fertility--treatments-6534d93ee4b00ae81f8275f6"
                  className="btn-buyNowLink"
                >
                  <button className="btn btn-buyNow">Enroll Now</button>
                </Link>
              </div>
            </div>
          </div>
          <div className="card card-course-main">
            <img src={Image2} alt="Image1" />
            <div className="main-card-content">
              <h5>
              Diploma in ayurved male & female infertility management
              </h5>
              {/* <p>A 6 month diploma course to make you expect.</p> */}
              {/* <ul className="list-course-card">
                <li className="list-course-card-item">24 week course</li>
                <li className="list-course-card-item">3 lecture/week</li>
                <li className="list-course-card-item">
                  Overview of gynaecological diseases
                </li>
                <li className="list-course-card-item">
                  Detail study of male & female infertility
                </li>
                <li className="list-course-card-item">
                  Treatment protocol of gynaecological diseases
                </li>
                <li className="list-course-card-item">Case studies</li>
                <li className="list-course-card-item">
                  {" "}
                  Practical demonstration
                </li>
                <li className="list-course-card-item">Diploma Certificate</li>
              </ul> */}
              <div className="button-buyNow" style={{marginTop : '50px'}}>
                <Link to="https://tanwishinstitute.graphy.com/courses/Diploma-in-ayurved-male--female-infertility-management-6534dd41e4b067c43f4465cc" className="btn-buyNowLink">
                  <button className="btn btn-buyNow">Enroll Now</button>
                </Link>
              </div>
            </div>
          </div>
          <div className="card card-course-main">
            <img src={Image3} alt="Image3" />
            <div className="main-card-content">
              <h5>Ayurvedic Garbhasanskar Trainer course</h5>
              {/* <p>A 6 month diploma course to make you Trainer.</p> */}
              {/* <ul className="list-course-card">
                <li className="list-course-card-item">
                  Pregnant, non-pregnant
                </li>
                <li className="list-course-card-item">Ladies can joim</li>
                <li className="list-course-card-item">
                  Monthly development of baby
                </li>
                <li className="list-course-card-item">
                  Monthwise diet & Lifestyle
                </li>
                <li className="list-course-card-item">
                  Music, mantra in pregnancy
                </li>
                <li className="list-course-card-item">Expert guest lecture</li>
                <li className="list-course-card-item">
                  {" "}
                  Yoga & Breathing exersise guidance
                </li>
                <li className="list-course-card-item">
                  Do's & Dont in pregnancy
                </li>
              </ul> */}
              <div className="button-buyNow" style={{marginTop : '70px'}}>
                <Link
                  to="https://tanwishinstitute.graphy.com/courses/Ayurvedic-Garbhasanskar-Trainer-course-6534de68e4b0b8456de0aede-6534de68e4b0b8456de0aede"
                  className="btn-buyNowLink"
                >
                  <button className="btn btn-buyNow">Enroll Now</button>
                </Link>
              </div>
            </div>
          </div>
          <div className="card card-course-main">
            <img src={dermatology} alt="Image1" />
            <div className="main-card-content">
              <h5>Diploma course in ayurvedic dermatology & cosmetology</h5>
              {/* <p>A 3 month certificate course .</p>
              <ul className="list-course-card">
                <li className="list-course-card-item">5 week course</li>
                <li className="list-course-card-item">4 hours Per week</li>
                <li className="list-course-card-item">
                  Case studies on male & female fertility
                </li>
                <li className="list-course-card-item">
                  Protocol of patient management in fertility
                </li>
                <li className="list-course-card-item">
                  Lifetime support in fertlity cases
                </li>
                <li className="list-course-card-item">Expert guest lecture</li>
                <li className="list-course-card-item">
                  {" "}
                  Weekly doubt solving session
                </li>
                <li className="list-course-card-item">
                  Completly online course
                </li>
                <li className="list-course-card-item">
                  Ug, pg students can join
                </li>
              </ul> */}
              <div className="button-buyNow" style={{marginTop : '50px'}}>
                <Link
                  target="_blank"
                  to="https://tanwishinstitute.graphy.com/courses/Diploma-course-in-ayurvedic-dermatology--cosmetology-6534dbe2e4b0b8456de0ae5d"
                  className="btn-buyNowLink"
                >
                  <button className="btn btn-buyNow">Enroll Now</button>
                </Link>
              </div>
            </div>
          </div>
        {/* </div> */}
        {/* <div
          className="container-course-main-section-row"
          style={{ marginTop: "5em" }}
        > */}
          
          <div className="card card-course-main">
            <img src={orthopedic} alt="Image1" />
            <div className="main-card-content">
              <h5>Diploma course in ayurved orthopedic & rheumatology</h5>
              {/* <p>A 6 month diploma course to make you expect.</p>
              <ul className="list-course-card">
                <li className="list-course-card-item">24 week course</li>
                <li className="list-course-card-item">3 lecture/week</li>
                <li className="list-course-card-item">
                  Overview of gynaecological diseases
                </li>
                <li className="list-course-card-item">
                  Detail study of male & female infertility
                </li>
                <li className="list-course-card-item">
                  Treatment protocol of gynaecological diseases
                </li>
                <li className="list-course-card-item">Case studies</li>
                <li className="list-course-card-item">
                  {" "}
                  Practical demonstration
                </li>
                <li className="list-course-card-item">Diploma Certificate</li>
              </ul> */}
              <div className="button-buyNow" style={{marginTop : '50px'}}>
                <Link
                  target="_blank"
                  to="https://tanwishinstitute.graphy.com/courses/Diploma-course-in-ayurved-orthopedic--rheumatology-6534e233e4b0b8456de0af94"
                  className="btn-buyNowLink"
                >
                  <button className="btn btn-buyNow">Enroll Now</button>
                </Link>
              </div>
            </div>
          </div>
          <div className="card card-course-main">
            <img src={gynaeocology} alt="Image1" />
            <div className="main-card-content">
              <h5>Diploma course in Ayurved Gynaecology</h5>
              {/* <p>A 6 month diploma course to make you Trainer.</p>
              <ul className="list-course-card">
                <li className="list-course-card-item">
                  Pregnant, non-pregnant
                </li>
                <li className="list-course-card-item">Ladies can joim</li>
                <li className="list-course-card-item">
                  Monthly development of baby
                </li>
                <li className="list-course-card-item">
                  Monthwise diet & Lifestyle
                </li>
                <li className="list-course-card-item">
                  Music, mantra in pregnancy
                </li>
                <li className="list-course-card-item">Expert guest lecture</li>
                <li className="list-course-card-item">
                  {" "}
                  Yoga & Breathing exersise guidance
                </li>
                <li className="list-course-card-item">
                  Do's & Dont in pregnancy
                </li>
              </ul> */}
              <div className="button-buyNow" style={{marginTop : '70px'}}>
                <Link
                  target="_blank"
                  to="https://tanwishinstitute.graphy.com/courses/Diploma-course-in-Ayurved-Gynaecology-6534e2cde4b0b8456de0afc1"
                  className="btn-buyNowLink"
                >
                  <button className="btn btn-buyNow">Enroll Now</button>
                </Link>
              </div>
            </div>
          </div>
        {/* </div> */}
        {/* <div
          className="container-course-main-section-row"
          style={{ marginTop: "5em" }}
        > */}
          <div className="card card-course-main">
            <img src={autoimmune} alt="Image1" />
            <div className="main-card-content">
              <h5>
                Diploma in ayurveidc Management of autoimmune Diseases with
                rasayana chikitsa
              </h5>
              {/* <p>A 3 month certificate course .</p>
              <ul className="list-course-card">
                <li className="list-course-card-item">5 week course</li>
                <li className="list-course-card-item">4 hours Per week</li>
                <li className="list-course-card-item">
                  Case studies on male & female fertility
                </li>
                <li className="list-course-card-item">
                  Protocol of patient management in fertility
                </li>
                <li className="list-course-card-item">
                  Lifetime support in fertlity cases
                </li>
                <li className="list-course-card-item">Expert guest lecture</li>
                <li className="list-course-card-item">
                  {" "}
                  Weekly doubt solving session
                </li>
                <li className="list-course-card-item">
                  Completly online course
                </li>
                <li className="list-course-card-item">
                  Ug, pg students can join
                </li>
              </ul> */}
              <div className="button-buyNow" >
                <Link
                  target="_blank"
                  to="https://tanwishinstitute.graphy.com/courses/Diploma-in-ayurveidc-Management-of-autoimmune-Diseases-with-rasayana-chikitsa-6534e633e4b0464a1c7cdf27"
                  className="btn-buyNowLink"
                >
                  <button className="btn btn-buyNow">Enroll Now</button>
                </Link>
              </div>
            </div>
          </div>
          <div className="card card-course-main">
            <img src={neurology} alt="Image1" />
            <div className="main-card-content">
              <h5>Diploma in Ayurvedic Neurology</h5>
              {/* <p>A 6 month diploma course to make you expect.</p>
              <ul className="list-course-card">
                <li className="list-course-card-item">24 week course</li>
                <li className="list-course-card-item">3 lecture/week</li>
                <li className="list-course-card-item">
                  Overview of gynaecological diseases
                </li>
                <li className="list-course-card-item">
                  Detail study of male & female infertility
                </li>
                <li className="list-course-card-item">
                  Treatment protocol of gynaecological diseases
                </li>
                <li className="list-course-card-item">Case studies</li>
                <li className="list-course-card-item">
                  {" "}
                  Practical demonstration
                </li>
                <li className="list-course-card-item">Diploma Certificate</li>
              </ul> */}
              <div className="button-buyNow" style={{marginTop : '70px'}}>
                <Link
                  target="_blank"
                  to="https://tanwishinstitute.graphy.com/courses/Diploma-in-Ayurvedic-Neurology--6534e6c2e4b067c43f4467b8"
                  className="btn-buyNowLink"
                >
                  <button className="btn btn-buyNow">Enroll Now</button>
                </Link>
              </div>
            </div>
          </div>
          <div className="card card-course-main">
            <img src={psychiatry} alt="Image1" />
            <div className="main-card-content">
              <h5>Diploma in ayurved psychiatry</h5>
              {/* <p>A 6 month diploma course to make you Trainer.</p>
              <ul className="list-course-card">
                <li className="list-course-card-item">
                  Pregnant, non-pregnant
                </li>
                <li className="list-course-card-item">Ladies can joim</li>
                <li className="list-course-card-item">
                  Monthly development of baby
                </li>
                <li className="list-course-card-item">
                  Monthwise diet & Lifestyle
                </li>
                <li className="list-course-card-item">
                  Music, mantra in pregnancy
                </li>
                <li className="list-course-card-item">Expert guest lecture</li>
                <li className="list-course-card-item">
                  {" "}
                  Yoga & Breathing exersise guidance
                </li>
                <li className="list-course-card-item">
                  Do's & Dont in pregnancy
                </li>
              </ul> */}
              <div className="button-buyNow" style={{marginTop : '70px'}}>
                <Link
                  target="_blank"
                  to="https://tanwishinstitute.graphy.com/courses/Diploma-in-ayurved-psychiatry--6534e78be4b00c9bb1ab55fb"
                  className="btn-buyNowLink"
                >
                  <button className="btn btn-buyNow">Enroll Now</button>
                </Link>
              </div>
            </div>
          </div>
        {/* </div> */}
        {/* <div
          className="container-course-main-section-row"
          style={{ marginTop: "5em" }}
        > */}
          <div className="card card-course-main">
            <img src={malesexualhealth} alt="Image1" />
            <div className="main-card-content">
              <h5>Diploma in ayurved male sexual health</h5>
              {/* <p>A 3 month certificate course .</p>
              <ul className="list-course-card">
                <li className="list-course-card-item">5 week course</li>
                <li className="list-course-card-item">4 hours Per week</li>
                <li className="list-course-card-item">
                  Case studies on male & female fertility
                </li>
                <li className="list-course-card-item">
                  Protocol of patient management in fertility
                </li>
                <li className="list-course-card-item">
                  Lifetime support in fertlity cases
                </li>
                <li className="list-course-card-item">Expert guest lecture</li>
                <li className="list-course-card-item">
                  {" "}
                  Weekly doubt solving session
                </li>
                <li className="list-course-card-item">
                  Completly online course
                </li>
                <li className="list-course-card-item">
                  Ug, pg students can join
                </li>
              </ul> */}
              <div className="button-buyNow" style={{marginTop : '70px'}}>
                <Link
                  target="_blank"
                  to="https://tanwishinstitute.graphy.com/courses/Diploma-in-ayurved-male-sexual-health-6534e838e4b0b8456de0b11b-6534e838e4b0b8456de0b11b"
                  className="btn-buyNowLink"
                >
                  <button className="btn btn-buyNow">Enroll Now</button>
                </Link>
              </div>
            </div>
          </div>
          <div className="card card-course-main">
            <img src={AyurvedicDieteticsNutrition} alt="Image1" />
            <div className="main-card-content">
              <h5>Diploma Course in Ayurvedic Dietetics & Nutrition</h5>
              {/* <p>A 6 month diploma course to make you expect.</p>
              <ul className="list-course-card">
                <li className="list-course-card-item">24 week course</li>
                <li className="list-course-card-item">3 lecture/week</li>
                <li className="list-course-card-item">
                  Overview of gynaecological diseases
                </li>
                <li className="list-course-card-item">
                  Detail study of male & female infertility
                </li>
                <li className="list-course-card-item">
                  Treatment protocol of gynaecological diseases
                </li>
                <li className="list-course-card-item">Case studies</li>
                <li className="list-course-card-item">
                  {" "}
                  Practical demonstration
                </li>
                <li className="list-course-card-item">Diploma Certificate</li>
              </ul> */}
              <div className="button-buyNow" style={{marginTop : '50px'}}>
                <Link
                  target="_blank"
                  to="https://tanwishinstitute.graphy.com/courses/Diploma-Course-in-Ayurvedic-Dietetics--Nutrition-655f01f1e4b0b24178a36f04-655f01f1e4b0b24178a36f04"
                  className="btn-buyNowLink"
                >
                  <button className="btn btn-buyNow">Enroll Now</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainSection;
