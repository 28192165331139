import React, {useEffect} from 'react';
import Image3 from '../../../assets/image-3.png';
import './style/whyChooseUs.css';
import Aos from 'aos';
import 'aos/dist/aos.css';

const WhyChooseUs = () => {

    useEffect(()=>{
        Aos.init({
          duration: 2000,
          offset:100,
        });
    },[]);
  return (
   <section className='whyChooseUs d-flex justify-content-center align-items-center'>
    <div className="container whyChoose-container d-flex justify-content-center align-items-center  flex-column">
        <div className="container-heading heading">
            <h1 className='h1' data-aos="fade-right" data-aos-easing="ease">Why to choose Us ?</h1>
        </div>
        <div className="content-container d-flex justify-content-center align-items-start">
            <div className="image-box">
               <img src={Image3} alt="" className='image'/>
            </div>
            <div className="text-box">
               <p className='paragraph'>
               Because we aim to uplift not only theoretical knowledge but actual conceptual and practical implementation of various specialities in ayurveda .
               </p>
            </div>
            <div className="quate-content" data-aos="fade-left" data-aos-easing="ease">
                <h3>“ Opportunity only knocks once , Grab it or you will lose it “</h3>
            </div>
        </div>
    </div>
    
   </section>
  )
}

export default WhyChooseUs
