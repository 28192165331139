import React from 'react';
import './style/privacyPolicyPage.css';

const PrivacyPolicyPage = () => {
  return (
    <>
  <style
    dangerouslySetInnerHTML={{
      __html:
        "\n  [data-custom-class='body'], [data-custom-class='body'] * {\n          background: transparent !important;\n        }\n[data-custom-class='title'], [data-custom-class='title'] * {\n          font-family: Arial !important;\nfont-size: 26px !important;\ncolor: #000000 !important;\n        }\n[data-custom-class='subtitle'], [data-custom-class='subtitle'] * {\n          font-family: Arial !important;\ncolor: #595959 !important;\nfont-size: 14px !important;\n        }\n[data-custom-class='heading_1'], [data-custom-class='heading_1'] * {\n          font-family: Arial !important;\nfont-size: 19px !important;\ncolor: #000000 !important;\n        }\n[data-custom-class='heading_2'], [data-custom-class='heading_2'] * {\n          font-family: Arial !important;\nfont-size: 17px !important;\ncolor: #000000 !important;\n        }\n[data-custom-class='body_text'], [data-custom-class='body_text'] * {\n          color: #595959 !important;\nfont-size: 14px !important;\nfont-family: Arial !important;\n        }\n[data-custom-class='link'], [data-custom-class='link'] * {\n          color: #3030F1 !important;\nfont-size: 14px !important;\nfont-family: Arial !important;\nword-break: break-word !important;\n        }\n"
    }}
  />
  <div data-custom-class="body" >
    <div data-custom-class="container">
      <strong>
        <span style={{ fontSize: 26 }}>
          <span data-custom-class="title">
            <bdt className="block-component" />
            <bdt className="question">PRIVACY POLICY</bdt>
            <bdt className="statement-end-if-in-editor" />
          </span>
        </span>
      </strong>
    </div>
    <div>
      <br />
    </div>
    <div>
      <span style={{ color: "rgb(127, 127, 127)" }}>
        <strong>
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="subtitle">
              Last updated <bdt className="question">October 05, 2023</bdt>
            </span>
          </span>
        </strong>
      </span>
    </div>
    <div>
      <br />
    </div>
    <div>
      <br />
    </div>
    <div>
      <br />
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ color: "rgb(127, 127, 127)" }}>
        <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
          <span data-custom-class="body_text">
            This privacy notice for{" "}
            <bdt className="question">
              TanwishLIfe
              <bdt className="block-component" />
            </bdt>{" "}
            (<bdt className="block-component" />'<strong>we</strong>', '
            <strong>us</strong>', or '<strong>our</strong>'
            <bdt className="else-block" />
          </span>
          <span data-custom-class="body_text">
            ), describes how and why we might collect, store, use, and/or share
            (<bdt className="block-component" />'<strong>process</strong>'
            <bdt className="else-block" />) your information when you use our
            services (<bdt className="block-component" />'
            <strong>Services</strong>'<bdt className="else-block" />
            ), such as when you:
          </span>
        </span>
      </span>
      <span style={{ fontSize: 15 }}>
        <span style={{ color: "rgb(127, 127, 127)" }}>
          <span data-custom-class="body_text">
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <bdt className="block-component" />
              </span>
            </span>
          </span>
        </span>
      </span>
    </div>
    <ul>
      <li style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              Visit our website
              <bdt className="block-component" /> at{" "}
              <bdt className="question">
                <a
                  href="https://www.tanwishlife.com"
                  target="_blank"
                  data-custom-class="link"
                  rel='noreferrer'
                >
                  https://www.tanwishlife.com
                </a>
              </bdt>
              <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15 }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <bdt className="statement-end-if-in-editor">
                          , or any website of ours that links to this privacy
                          notice
                        </bdt>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
      </li>
    </ul>
    <div>
      <bdt className="block-component">
        <span style={{ fontSize: 15 }}>
          <span style={{ fontSize: 15 }}>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
      </bdt>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span data-custom-class="body_text">
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <bdt className="block-component" />
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <ul>
        <li style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                Engage with us in other related ways, including any sales,
                marketing, or events
                <span style={{ fontSize: 15 }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: 15 }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <bdt className="statement-end-if-in-editor" />
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span data-custom-class="body_text">
              <strong>Questions or concerns?&nbsp;</strong>Reading this privacy
              notice will help you understand your privacy rights and choices.
              If you do not agree with our policies and practices, please do not
              use our Services. If you still have any questions or concerns,
              please contact us at{" "}
              <bdt className="question">tanwish.life@gmail.com</bdt>.
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <strong>
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="heading_1">SUMMARY OF KEY POINTS</span>
          </span>
        </strong>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <strong>
              <em>
                This summary provides key points from our privacy notice, but
                you can find out more details about any of these topics by
                clicking the link following each key point or by using our&nbsp;
              </em>
            </strong>
          </span>
        </span>
        <a data-custom-class="link" href="#toc">
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text">
              <strong>
                <em>table of contents</em>
              </strong>
            </span>
          </span>
        </a>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <strong>
              <em>&nbsp;below to find the section you are looking for.</em>
            </strong>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <strong>What personal information do we process?</strong> When you
            visit, use, or navigate our Services, we may process personal
            information depending on how you interact with us and the Services,
            the choices you make, and the products and features you use. Learn
            more about&nbsp;
          </span>
        </span>
        <a data-custom-class="link" href="#personalinfo">
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text">
              personal information you disclose to us
            </span>
          </span>
        </a>
        <span data-custom-class="body_text">.</span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <strong>Do we process any sensitive personal information?</strong>{" "}
            <bdt className="block-component" />
            We do not process sensitive personal information.
            <bdt className="else-block" />
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <strong>Do we receive any information from third parties?</strong>{" "}
            <bdt className="block-component" />
            We do not receive any information from third parties.
            <bdt className="else-block" />
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <strong>How do we process your information?</strong> We process your
            information to provide, improve, and administer our Services,
            communicate with you, for security and fraud prevention, and to
            comply with law. We may also process your information for other
            purposes with your consent. We process your information only when we
            have a valid legal reason to do so. Learn more about&nbsp;
          </span>
        </span>
        <a data-custom-class="link" href="#infouse">
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text">
              how we process your information
            </span>
          </span>
        </a>
        <span data-custom-class="body_text">.</span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <strong>
              In what situations and with which{" "}
              <bdt className="block-component" />
              parties do we share personal information?
            </strong>{" "}
            We may share information in specific situations and with specific{" "}
            <bdt className="block-component" />
            third parties. Learn more about&nbsp;
          </span>
        </span>
        <a data-custom-class="link" href="#whoshare">
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text">
              when and with whom we share your personal information
            </span>
          </span>
        </a>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            .<bdt className="block-component" />
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <strong>How do we keep your information safe?</strong> We have{" "}
            <bdt className="block-component" />
            organisational
            <bdt className="else-block" /> and technical processes and
            procedures in place to protect your personal information. However,
            no electronic transmission over the internet or information storage
            technology can be guaranteed to be 100% secure, so we cannot promise
            or guarantee that hackers, cybercriminals, or other{" "}
            <bdt className="block-component" />
            unauthorised
            <bdt className="else-block" /> third parties will not be able to
            defeat our security and improperly collect, access, steal, or modify
            your information. Learn more about&nbsp;
          </span>
        </span>
        <a data-custom-class="link" href="#infosafe">
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text">
              how we keep your information safe
            </span>
          </span>
        </a>
        <span data-custom-class="body_text">.</span>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <bdt className="statement-end-if-in-editor" />
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <strong>What are your rights?</strong> Depending on where you are
            located geographically, the applicable privacy law may mean you have
            certain rights regarding your personal information. Learn more
            about&nbsp;
          </span>
        </span>
        <a data-custom-class="link" href="#privacyrights">
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text">your privacy rights</span>
          </span>
        </a>
        <span data-custom-class="body_text">.</span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <strong>How do you exercise your rights?</strong> The easiest way to
            exercise your rights is by{" "}
            <bdt className="block-component">submitting a&nbsp;</bdt>
          </span>
        </span>
        <a
          data-custom-class="link"
          href="https://app.termly.io/notify/9bc60071-4838-4190-b361-3919e3a0bdda"
          rel="noopener noreferrer"
          target="_blank"
        >
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text">
              data subject access request
            </span>
          </span>
        </a>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <bdt className="block-component" />, or by contacting us. We will
            consider and act upon any request in accordance with applicable data
            protection laws.
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            Want to learn more about what we do with any information we
            collect?&nbsp;
          </span>
        </span>
        <a data-custom-class="link" href="#toc">
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text">
              Review the privacy notice in full
            </span>
          </span>
        </a>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">.</span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div id="toc" style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span style={{ color: "rgb(0, 0, 0)" }}>
              <strong>
                <span data-custom-class="heading_1">TABLE OF CONTENTS</span>
              </strong>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <a data-custom-class="link" href="#infocollect">
            <span style={{ color: "rgb(89, 89, 89)" }}>
              1. WHAT INFORMATION DO WE COLLECT?
            </span>
          </a>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <a data-custom-class="link" href="#infouse">
            <span style={{ color: "rgb(89, 89, 89)" }}>
              2. HOW DO WE PROCESS YOUR INFORMATION?
              <bdt className="block-component" />
            </span>
          </a>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span style={{ color: "rgb(89, 89, 89)" }}>
            <a data-custom-class="link" href="#whoshare">
              3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
            </a>
          </span>
          <span data-custom-class="body_text">
            <bdt className="block-component" />
          </span>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <bdt className="block-component" />
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <a data-custom-class="link" href="#cookies">
            <span style={{ color: "rgb(89, 89, 89)" }}>
              4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
            </span>
          </a>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <bdt className="statement-end-if-in-editor" />
                </span>
              </span>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <bdt className="block-component" />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <a data-custom-class="link" href="#sociallogins">
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                </span>
              </span>
            </span>
          </a>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <bdt className="statement-end-if-in-editor" />
                    </span>
                  </span>
                  <bdt className="block-component" />
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <a data-custom-class="link" href="#inforetain">
            <span style={{ color: "rgb(89, 89, 89)" }}>
              6. HOW LONG DO WE KEEP YOUR INFORMATION?
            </span>
          </a>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <a data-custom-class="link" href="#infosafe">
            <span style={{ color: "rgb(89, 89, 89)" }}>
              7. HOW DO WE KEEP YOUR INFORMATION SAFE?
            </span>
          </a>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <bdt className="statement-end-if-in-editor" />
                  <bdt className="block-component" />
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <a data-custom-class="link" href="#infominors">
            <span style={{ color: "rgb(89, 89, 89)" }}>
              8. DO WE COLLECT INFORMATION FROM MINORS?
            </span>
          </a>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <bdt className="statement-end-if-in-editor" />
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span style={{ color: "rgb(89, 89, 89)" }}>
            <a data-custom-class="link" href="#privacyrights">
              9. WHAT ARE YOUR PRIVACY RIGHTS?
            </a>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <a data-custom-class="link" href="#DNT">
            <span style={{ color: "rgb(89, 89, 89)" }}>
              10. CONTROLS FOR DO-NOT-TRACK FEATURES
              <bdt className="block-component" />
            </span>
            <bdt className="block-component">
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text" />
              </span>
            </bdt>
          </a>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <a data-custom-class="link" href="#otherlaws">
          <span style={{ fontSize: 15 }}>
            11. DO OTHER REGIONS HAVE SPECIFIC PRIVACY RIGHTS?
          </span>
        </a>
        <span style={{ fontSize: 15 }}>
          <bdt className="statement-end-if-in-editor">
            <span data-custom-class="body_text" />
          </bdt>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <bdt className="block-component">
          <span style={{ fontSize: 15 }} />
        </bdt>
        <bdt className="block-component" />
        <bdt className="block-component" />
        <bdt className="block-component" />
        <bdt className="block-component" />
        <bdt className="block-component" />
        <bdt className="block-component" />
        <bdt className="block-component" />
        <bdt className="block-component" />
        <bdt className="block-component" />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <a data-custom-class="link" href="#policyupdates">
            <span style={{ color: "rgb(89, 89, 89)" }}>
              12. DO WE MAKE UPDATES TO THIS NOTICE?
            </span>
          </a>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <a data-custom-class="link" href="#contact">
          <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
            13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
          </span>
        </a>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <a data-custom-class="link" href="#request">
          <span style={{ color: "rgb(89, 89, 89)" }}>
            14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
            YOU?
          </span>
        </a>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div id="infocollect" style={{ lineHeight: "1.5" }}>
        <span style={{ color: "rgb(127, 127, 127)" }}>
          <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                  <strong>
                    <span data-custom-class="heading_1">
                      1. WHAT INFORMATION DO WE COLLECT?
                    </span>
                  </strong>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div id="personalinfo" style={{ lineHeight: "1.5" }}>
        <span data-custom-class="heading_2" style={{ color: "rgb(0, 0, 0)" }}>
          <span style={{ fontSize: 15 }}>
            <strong>Personal information you disclose to us</strong>
          </span>
        </span>
      </div>
      <div>
        <div>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
              <span data-custom-class="body_text">
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <strong>
                        <em>In Short:</em>
                      </strong>
                    </span>
                  </span>
                </span>
              </span>
              <span data-custom-class="body_text">
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <strong>
                        <em>&nbsp;</em>
                      </strong>
                      <em>
                        We collect personal information that you provide to us.
                      </em>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              We collect personal information that you voluntarily provide to us
              when you{" "}
              <span style={{ fontSize: 15 }}>
                <bdt className="block-component" />
              </span>
              register on the Services,&nbsp;
            </span>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <span style={{ fontSize: 15 }}>
                  <bdt className="statement-end-if-in-editor" />
                </span>
              </span>
              <span data-custom-class="body_text">
                express an interest in obtaining information about us or our
                products and Services, when you participate in activities on the
                Services, or otherwise when you contact us.
              </span>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <span style={{ fontSize: 15 }}>
                <bdt className="block-component" />
              </span>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <strong>Personal Information Provided by You.</strong> The
              personal information that we collect depends on the context of
              your interactions with us and the Services, the choices you make,
              and the products and features you use. The personal information we
              collect may include the following:
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <bdt className="forloop-component" />
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <ul>
        <li style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <bdt className="question">names</bdt>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <bdt className="forloop-component" />
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <ul>
        <li style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <bdt className="question">phone numbers</bdt>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <bdt className="forloop-component" />
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <ul>
        <li style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <bdt className="question">email addresses</bdt>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <bdt className="forloop-component" />
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <ul>
        <li style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <bdt className="question">usernames</bdt>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <bdt className="forloop-component" />
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <ul>
        <li style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <bdt className="question">passwords</bdt>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <bdt className="forloop-component" />
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <ul>
        <li style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <bdt className="question">
                      contact or authentication data
                    </bdt>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <bdt className="forloop-component" />
                </span>
                <span data-custom-class="body_text">
                  <bdt className="statement-end-if-in-editor" />
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div id="sensitiveinfo" style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <strong>Sensitive Information.</strong>{" "}
            <bdt className="block-component" />
            We do not process sensitive information.
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <bdt className="else-block" />
          </span>
        </span>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <bdt className="block-component" />
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <strong>Payment Data.</strong> We may collect data necessary to
              process your payment if you make purchases, such as your payment
              instrument number, and the security code associated with your
              payment instrument. All payment data is stored by
              <bdt className="forloop-component" />
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15 }}>
                      <span data-custom-class="body_text">
                        <bdt className="block-component" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>{" "}
              <bdt className="question">PhonePay</bdt>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          <span style={{ fontSize: 15 }}>
                            <span data-custom-class="body_text">
                              <bdt className="block-component" />
                            </span>
                          </span>
                        </span>
                        <span data-custom-class="body_text">
                          <span style={{ fontSize: 15 }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span style={{ fontSize: 15 }}>
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        <span style={{ fontSize: 15 }}>
                                          <span data-custom-class="body_text">
                                            <bdt className="forloop-component" />
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                    . You may find their privacy notice link(s) here:
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          <bdt className="forloop-component" />
                          <span
                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                          >
                            <span
                              style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                            >
                              <span data-custom-class="body_text">
                                <span style={{ fontSize: 15 }}>
                                  <span data-custom-class="body_text">
                                    <bdt className="block-component" />
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>{" "}
                    <bdt className="question">__________</bdt>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          <span
                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                          >
                            <span
                              style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                            >
                              <span data-custom-class="body_text">
                                <span
                                  style={{
                                    fontSize: 15,
                                    color: "rgb(89, 89, 89)"
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: 15,
                                      color: "rgb(89, 89, 89)"
                                    }}
                                  >
                                    <span data-custom-class="body_text">
                                      <span style={{ fontSize: 15 }}>
                                        <span data-custom-class="body_text">
                                          <bdt className="block-component" />
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                          <bdt className="forloop-component" />
                          <span style={{ fontSize: 15 }}>
                            <span data-custom-class="body_text">
                              .<bdt className="block-component" />
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <bdt className="statement-end-if-in-editor">
                    <bdt className="block-component" />
                  </bdt>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <strong>Social Media Login Data.&nbsp;</strong>We may provide you
              with the option to register with us using your existing social
              media account details, like your Facebook, Twitter, or other
              social media account. If you choose to register in this way, we
              will collect the information described in the section called{" "}
              <bdt className="block-component" />'<bdt className="else-block" />
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15 }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <a data-custom-class="link" href="#sociallogins">
                        HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                      </a>
                    </span>
                  </span>
                </span>
              </span>
              <bdt className="block-component" />'<bdt className="else-block" />{" "}
              below.
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <span style={{ fontSize: 15 }}>
                <bdt className="statement-end-if-in-editor">
                  <bdt className="statement-end-if-in-editor" />
                </bdt>
              </span>
            </span>
          </span>
        </span>
        <bdt className="block-component" />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              All personal information that you provide to us must be true,
              complete, and accurate, and you must notify us of any changes to
              such personal information.
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <bdt className="block-component" />
            </span>
            <span data-custom-class="body_text">
              <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <bdt className="statement-end-if-in-editor">
                        <bdt className="block-component" />
                      </bdt>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <bdt className="block-component" />
          </span>
        </span>
      </div>
      <div id="infouse" style={{ lineHeight: "1.5" }}>
        <span style={{ color: "rgb(127, 127, 127)" }}>
          <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                  <strong>
                    <span data-custom-class="heading_1">
                      2. HOW DO WE PROCESS YOUR INFORMATION?
                    </span>
                  </strong>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
              <span data-custom-class="body_text">
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <strong>
                        <em>In Short:&nbsp;</em>
                      </strong>
                      <em>
                        We process your information to provide, improve, and
                        administer our Services, communicate with you, for
                        security and fraud prevention, and to comply with law.
                        We may also process your information for other purposes
                        with your consent.
                      </em>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <strong>
                We process your personal information for a variety of reasons,
                depending on how you interact with our Services, including:
              </strong>
              <bdt className="block-component" />
            </span>
          </span>
        </span>
      </div>
      <ul>
        <li style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <strong>
                  To facilitate account creation and authentication and
                  otherwise manage user accounts.&nbsp;
                </strong>
                We may process your information so you can create and log in to
                your account, as well as keep your account in working order.
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: 15 }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ fontSize: 15 }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                  <bdt className="statement-end-if-in-editor" />
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <bdt className="block-component" />
            </span>
          </span>
        </span>
      </div>
      <ul>
        <li style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <strong>
                  To deliver and facilitate delivery of services to the
                  user.&nbsp;
                </strong>
                We may process your information to provide you with the
                requested service.
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span
                          style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                        >
                          <span data-custom-class="body_text">
                            <span style={{ fontSize: 15 }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                  <span style={{ fontSize: 15 }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <span data-custom-class="body_text">
                                        <bdt className="statement-end-if-in-editor" />
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <bdt className="block-component" />
            </span>
          </span>
        </span>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <bdt className="block-component" />
              </span>
            </span>
          </span>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <bdt className="block-component" />
                </span>
              </span>
            </span>
            <div style={{ lineHeight: "1.5" }}>
              <bdt className="block-component">
                <span style={{ fontSize: 15 }} />
              </bdt>
              <div style={{ lineHeight: "1.5" }}>
                <bdt className="block-component">
                  <span style={{ fontSize: 15 }} />
                </bdt>
                <div style={{ lineHeight: "1.5" }}>
                  <bdt className="block-component">
                    <span style={{ fontSize: 15 }} />
                  </bdt>
                  <div style={{ lineHeight: "1.5" }}>
                    <bdt className="block-component">
                      <span style={{ fontSize: 15 }}>
                        <span data-custom-class="body_text" />
                      </span>
                    </bdt>
                    <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                      <bdt className="block-component">
                        <span style={{ fontSize: 15 }} />
                      </bdt>
                    </p>
                    <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                      <bdt className="block-component">
                        <span style={{ fontSize: 15 }} />
                      </bdt>
                    </p>
                    <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                      <bdt className="block-component" />
                    </p>
                    <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                      <bdt className="block-component" />
                    </p>
                    <div style={{ lineHeight: "1.5" }}>
                      <bdt className="block-component">
                        <span style={{ fontSize: 15 }}>
                          <span data-custom-class="body_text" />
                        </span>
                      </bdt>
                      <div style={{ lineHeight: "1.5" }}>
                        <bdt className="block-component">
                          <span style={{ fontSize: 15 }} />
                        </bdt>
                        <div style={{ lineHeight: "1.5" }}>
                          <bdt className="block-component">
                            <span style={{ fontSize: 15 }} />
                          </bdt>
                        </div>
                        <ul>
                          <li style={{ lineHeight: "1.5" }}>
                            <span
                              style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                            >
                              <span
                                style={{
                                  fontSize: 15,
                                  color: "rgb(89, 89, 89)"
                                }}
                              >
                                <span data-custom-class="body_text">
                                  <strong>
                                    To deliver targeted advertising to
                                    you.&nbsp;
                                  </strong>
                                  We may process your information to develop and
                                  display <bdt className="block-component" />
                                  personalised
                                  <bdt className="else-block" /> content and
                                  advertising tailored to your interests,
                                  location, and more.
                                  <bdt className="block-component" />
                                </span>
                              </span>
                            </span>
                          </li>
                        </ul>
                        <div style={{ lineHeight: "1.5" }}>
                          <span style={{ fontSize: 15 }}>
                            <bdt className="block-component">
                              <span data-custom-class="body_text" />
                            </bdt>
                          </span>
                          <div style={{ lineHeight: "1.5" }}>
                            <bdt className="block-component">
                              <span style={{ fontSize: 15 }}>
                                <span data-custom-class="body_text" />
                              </span>
                            </bdt>
                            <div style={{ lineHeight: "1.5" }}>
                              <bdt className="block-component">
                                <span style={{ fontSize: 15 }}>
                                  <span data-custom-class="body_text" />
                                </span>
                              </bdt>
                              <div style={{ lineHeight: "1.5" }}>
                                <bdt className="block-component">
                                  <span style={{ fontSize: 15 }}>
                                    <span data-custom-class="body_text" />
                                  </span>
                                </bdt>
                                <div style={{ lineHeight: "1.5" }}>
                                  <bdt className="block-component">
                                    <span style={{ fontSize: 15 }}>
                                      <span data-custom-class="body_text" />
                                    </span>
                                  </bdt>
                                  <div style={{ lineHeight: "1.5" }}>
                                    <bdt className="block-component">
                                      <span style={{ fontSize: 15 }}>
                                        <span data-custom-class="body_text" />
                                      </span>
                                    </bdt>
                                    <div style={{ lineHeight: "1.5" }}>
                                      <bdt className="block-component">
                                        <span style={{ fontSize: 15 }}>
                                          <span data-custom-class="body_text" />
                                        </span>
                                      </bdt>
                                      <div style={{ lineHeight: "1.5" }}>
                                        <bdt className="block-component">
                                          <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text" />
                                          </span>
                                        </bdt>
                                        <div style={{ lineHeight: "1.5" }}>
                                          <bdt className="block-component">
                                            <span style={{ fontSize: 15 }}>
                                              <span data-custom-class="body_text" />
                                            </span>
                                          </bdt>
                                          <div style={{ lineHeight: "1.5" }}>
                                            <bdt className="block-component">
                                              <span style={{ fontSize: 15 }}>
                                                <span data-custom-class="body_text" />
                                              </span>
                                            </bdt>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <bdt className="block-component">
                                                <span style={{ fontSize: 15 }}>
                                                  <span data-custom-class="body_text" />
                                                </span>
                                              </bdt>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <bdt className="block-component">
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <span data-custom-class="body_text" />
                                                  </span>
                                                </bdt>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <bdt className="block-component">
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span data-custom-class="body_text" />
                                                    </span>
                                                  </bdt>
                                                  <bdt className="block-component">
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span data-custom-class="body_text" />
                                                    </span>
                                                  </bdt>
                                                  <bdt className="block-component">
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span data-custom-class="body_text" />
                                                    </span>
                                                  </bdt>
                                                  <bdt className="block-component">
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span data-custom-class="body_text" />
                                                    </span>
                                                  </bdt>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <br />
                                                </div>
                                                <div
                                                  id="whoshare"
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{
                                                      color:
                                                        "rgb(127, 127, 127)"
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                        fontSize: 15
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)"
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)"
                                                          }}
                                                        >
                                                          <span
                                                            id="control"
                                                            style={{
                                                              color:
                                                                "rgb(0, 0, 0)"
                                                            }}
                                                          >
                                                            <strong>
                                                              <span data-custom-class="heading_1">
                                                                3. WHEN AND WITH
                                                                WHOM DO WE SHARE
                                                                YOUR PERSONAL
                                                                INFORMATION?
                                                              </span>
                                                            </strong>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <br />
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: "rgb(89, 89, 89)"
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <strong>
                                                          <em>In Short:</em>
                                                        </strong>
                                                        <em>
                                                          &nbsp;We may share
                                                          information in
                                                          specific situations
                                                          described in this
                                                          section and/or with
                                                          the following{" "}
                                                          <bdt className="block-component" />
                                                          third parties.
                                                        </em>
                                                      </span>
                                                    </span>
                                                    &nbsp;
                                                  </span>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: "rgb(89, 89, 89)"
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <bdt className="block-component" />
                                                      </span>
                                                    </span>
                                                  </span>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <br />
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      We{" "}
                                                      <bdt className="block-component" />
                                                      may need to share your
                                                      personal information in
                                                      the following situations:
                                                    </span>
                                                  </span>
                                                </div>
                                                <ul>
                                                  <li
                                                    style={{
                                                      lineHeight: "1.5"
                                                    }}
                                                  >
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <strong>
                                                          Business Transfers.
                                                        </strong>{" "}
                                                        We may share or transfer
                                                        your information in
                                                        connection with, or
                                                        during negotiations of,
                                                        any merger, sale of
                                                        company assets,
                                                        financing, or
                                                        acquisition of all or a
                                                        portion of our business
                                                        to another company.
                                                      </span>
                                                    </span>
                                                  </li>
                                                </ul>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      <bdt className="block-component" />
                                                    </span>
                                                  </span>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5"
                                                    }}
                                                  >
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <bdt className="block-component">
                                                        <span data-custom-class="body_text" />
                                                      </bdt>
                                                    </span>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5"
                                                      }}
                                                    >
                                                      <bdt className="block-component">
                                                        <span
                                                          style={{
                                                            fontSize: 15
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text" />
                                                        </span>
                                                      </bdt>
                                                      <div
                                                        style={{
                                                          lineHeight: "1.5"
                                                        }}
                                                      >
                                                        <bdt className="block-component">
                                                          <span
                                                            style={{
                                                              fontSize: 15
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text" />
                                                          </span>
                                                        </bdt>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <bdt className="block-component">
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text" />
                                                            </span>
                                                          </bdt>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <bdt className="block-component">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text" />
                                                              </span>
                                                            </bdt>
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <bdt className="block-component">
                                                                        <span data-custom-class="heading_1" />
                                                                      </bdt>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            id="cookies"
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(127, 127, 127)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      id="control"
                                                                      style={{
                                                                        color:
                                                                          "rgb(0, 0, 0)"
                                                                      }}
                                                                    >
                                                                      <strong>
                                                                        <span data-custom-class="heading_1">
                                                                          4. DO
                                                                          WE USE
                                                                          COOKIES
                                                                          AND
                                                                          OTHER
                                                                          TRACKING
                                                                          TECHNOLOGIES?
                                                                        </span>
                                                                      </strong>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <strong>
                                                                    <em>
                                                                      In Short:
                                                                    </em>
                                                                  </strong>
                                                                  <em>
                                                                    &nbsp;We may
                                                                    use cookies
                                                                    and other
                                                                    tracking
                                                                    technologies
                                                                    to collect
                                                                    and store
                                                                    your
                                                                    information.
                                                                  </em>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  We may use
                                                                  cookies and
                                                                  similar
                                                                  tracking
                                                                  technologies
                                                                  (like web
                                                                  beacons and
                                                                  pixels) to
                                                                  access or
                                                                  store
                                                                  information.
                                                                  Specific
                                                                  information
                                                                  about how we
                                                                  use such
                                                                  technologies
                                                                  and how you
                                                                  can refuse
                                                                  certain
                                                                  cookies is set
                                                                  out in our
                                                                  Cookie Notice
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <bdt className="block-component" />
                                                                      .
                                                                    </span>
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                        fontSize: 15
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  "rgb(89, 89, 89)"
                                                                              }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                <bdt className="statement-end-if-in-editor" />
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                        fontSize: 15
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(89, 89, 89)"
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    "rgb(89, 89, 89)"
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  <bdt className="block-component" />
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            id="sociallogins"
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(127, 127, 127)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      id="control"
                                                                      style={{
                                                                        color:
                                                                          "rgb(0, 0, 0)"
                                                                      }}
                                                                    >
                                                                      <strong>
                                                                        <span data-custom-class="heading_1">
                                                                          5. HOW
                                                                          DO WE
                                                                          HANDLE
                                                                          YOUR
                                                                          SOCIAL
                                                                          LOGINS?
                                                                        </span>
                                                                      </strong>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <strong>
                                                                    <em>
                                                                      In
                                                                      Short:&nbsp;
                                                                    </em>
                                                                  </strong>
                                                                  <em>
                                                                    If you
                                                                    choose to
                                                                    register or
                                                                    log in to
                                                                    our Services
                                                                    using a
                                                                    social media
                                                                    account, we
                                                                    may have
                                                                    access to
                                                                    certain
                                                                    information
                                                                    about you.
                                                                  </em>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  Our Services
                                                                  offer you the
                                                                  ability to
                                                                  register and
                                                                  log in using
                                                                  your
                                                                  third-party
                                                                  social media
                                                                  account
                                                                  details (like
                                                                  your Facebook
                                                                  or Twitter
                                                                  logins). Where
                                                                  you choose to
                                                                  do this, we
                                                                  will receive
                                                                  certain
                                                                  profile
                                                                  information
                                                                  about you from
                                                                  your social
                                                                  media
                                                                  provider. The
                                                                  profile
                                                                  information we
                                                                  receive may
                                                                  vary depending
                                                                  on the social
                                                                  media provider
                                                                  concerned, but
                                                                  will often
                                                                  include your
                                                                  name, email
                                                                  address,
                                                                  friends list,
                                                                  and profile
                                                                  picture, as
                                                                  well as other
                                                                  information
                                                                  you choose to
                                                                  make public on
                                                                  such a social
                                                                  media
                                                                  platform.{" "}
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <bdt className="block-component" />
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  We will use
                                                                  the
                                                                  information we
                                                                  receive only
                                                                  for the
                                                                  purposes that
                                                                  are described
                                                                  in this
                                                                  privacy notice
                                                                  or that are
                                                                  otherwise made
                                                                  clear to you
                                                                  on the
                                                                  relevant
                                                                  Services.
                                                                  Please note
                                                                  that we do not
                                                                  control, and
                                                                  are not
                                                                  responsible
                                                                  for, other
                                                                  uses of your
                                                                  personal
                                                                  information by
                                                                  your
                                                                  third-party
                                                                  social media
                                                                  provider. We
                                                                  recommend that
                                                                  you review
                                                                  their privacy
                                                                  notice to
                                                                  understand how
                                                                  they collect,
                                                                  use, and share
                                                                  your personal
                                                                  information,
                                                                  and how you
                                                                  can set your
                                                                  privacy
                                                                  preferences on
                                                                  their sites
                                                                  and apps.
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                        fontSize: 15
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)",
                                                                            fontSize: 15
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  "rgb(89, 89, 89)"
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    color:
                                                                                      "rgb(89, 89, 89)"
                                                                                  }}
                                                                                >
                                                                                  <span data-custom-class="body_text">
                                                                                    <bdt className="statement-end-if-in-editor" />
                                                                                  </span>
                                                                                  <bdt className="block-component">
                                                                                    <span data-custom-class="body_text">
                                                                                      <bdt className="block-component" />
                                                                                    </span>
                                                                                  </bdt>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            id="inforetain"
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(127, 127, 127)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      id="control"
                                                                      style={{
                                                                        color:
                                                                          "rgb(0, 0, 0)"
                                                                      }}
                                                                    >
                                                                      <strong>
                                                                        <span data-custom-class="heading_1">
                                                                          6. HOW
                                                                          LONG
                                                                          DO WE
                                                                          KEEP
                                                                          YOUR
                                                                          INFORMATION?
                                                                        </span>
                                                                      </strong>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <strong>
                                                                    <em>
                                                                      In
                                                                      Short:&nbsp;
                                                                    </em>
                                                                  </strong>
                                                                  <em>
                                                                    We keep your
                                                                    information
                                                                    for as long
                                                                    as necessary
                                                                    to{" "}
                                                                    <bdt className="block-component" />
                                                                    fulfil
                                                                    <bdt className="else-block" />{" "}
                                                                    the purposes
                                                                    outlined in
                                                                    this privacy
                                                                    notice
                                                                    unless
                                                                    otherwise
                                                                    required by
                                                                    law.
                                                                  </em>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  We will only
                                                                  keep your
                                                                  personal
                                                                  information
                                                                  for as long as
                                                                  it is
                                                                  necessary for
                                                                  the purposes
                                                                  set out in
                                                                  this privacy
                                                                  notice, unless
                                                                  a longer
                                                                  retention
                                                                  period is
                                                                  required or
                                                                  permitted by
                                                                  law (such as
                                                                  tax,
                                                                  accounting, or
                                                                  other legal
                                                                  requirements).
                                                                  <bdt className="block-component" />{" "}
                                                                  No purpose in
                                                                  this notice
                                                                  will require
                                                                  us keeping
                                                                  your personal
                                                                  information
                                                                  for longer
                                                                  than{" "}
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <bdt className="block-component" />
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                  <bdt className="block-component" />
                                                                  the period of
                                                                  time in which
                                                                  users have an
                                                                  account with
                                                                  us
                                                                  <bdt className="block-component" />
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <bdt className="else-block" />
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                  .
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  When we have
                                                                  no ongoing
                                                                  legitimate
                                                                  business need
                                                                  to process
                                                                  your personal
                                                                  information,
                                                                  we will either
                                                                  delete or{" "}
                                                                  <bdt className="block-component" />
                                                                  anonymise
                                                                  <bdt className="else-block" />{" "}
                                                                  such
                                                                  information,
                                                                  or, if this is
                                                                  not possible
                                                                  (for example,
                                                                  because your
                                                                  personal
                                                                  information
                                                                  has been
                                                                  stored in
                                                                  backup
                                                                  archives),
                                                                  then we will
                                                                  securely store
                                                                  your personal
                                                                  information
                                                                  and isolate it
                                                                  from any
                                                                  further
                                                                  processing
                                                                  until deletion
                                                                  is possible.
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <bdt className="block-component" />
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            id="infosafe"
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(127, 127, 127)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      id="control"
                                                                      style={{
                                                                        color:
                                                                          "rgb(0, 0, 0)"
                                                                      }}
                                                                    >
                                                                      <strong>
                                                                        <span data-custom-class="heading_1">
                                                                          7. HOW
                                                                          DO WE
                                                                          KEEP
                                                                          YOUR
                                                                          INFORMATION
                                                                          SAFE?
                                                                        </span>
                                                                      </strong>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <strong>
                                                                    <em>
                                                                      In
                                                                      Short:&nbsp;
                                                                    </em>
                                                                  </strong>
                                                                  <em>
                                                                    We aim to
                                                                    protect your
                                                                    personal
                                                                    information
                                                                    through a
                                                                    system of{" "}
                                                                    <bdt className="block-component" />
                                                                    organisational
                                                                    <bdt className="else-block" />{" "}
                                                                    and
                                                                    technical
                                                                    security
                                                                    measures.
                                                                  </em>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  We have
                                                                  implemented
                                                                  appropriate
                                                                  and reasonable
                                                                  technical and{" "}
                                                                  <bdt className="block-component" />
                                                                  organisational
                                                                  <bdt className="else-block" />{" "}
                                                                  security
                                                                  measures
                                                                  designed to
                                                                  protect the
                                                                  security of
                                                                  any personal
                                                                  information we
                                                                  process.
                                                                  However,
                                                                  despite our
                                                                  safeguards and
                                                                  efforts to
                                                                  secure your
                                                                  information,
                                                                  no electronic
                                                                  transmission
                                                                  over the
                                                                  Internet or
                                                                  information
                                                                  storage
                                                                  technology can
                                                                  be guaranteed
                                                                  to be 100%
                                                                  secure, so we
                                                                  cannot promise
                                                                  or guarantee
                                                                  that hackers,
                                                                  cybercriminals,
                                                                  or other{" "}
                                                                  <bdt className="block-component" />
                                                                  unauthorised
                                                                  <bdt className="else-block" />{" "}
                                                                  third parties
                                                                  will not be
                                                                  able to defeat
                                                                  our security
                                                                  and improperly
                                                                  collect,
                                                                  access, steal,
                                                                  or modify your
                                                                  information.
                                                                  Although we
                                                                  will do our
                                                                  best to
                                                                  protect your
                                                                  personal
                                                                  information,
                                                                  transmission
                                                                  of personal
                                                                  information to
                                                                  and from our
                                                                  Services is at
                                                                  your own risk.
                                                                  You should
                                                                  only access
                                                                  the Services
                                                                  within a
                                                                  secure
                                                                  environment.
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <bdt className="statement-end-if-in-editor" />
                                                                  </span>
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <bdt className="block-component" />
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            id="infominors"
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(127, 127, 127)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      id="control"
                                                                      style={{
                                                                        color:
                                                                          "rgb(0, 0, 0)"
                                                                      }}
                                                                    >
                                                                      <strong>
                                                                        <span data-custom-class="heading_1">
                                                                          8. DO
                                                                          WE
                                                                          COLLECT
                                                                          INFORMATION
                                                                          FROM
                                                                          MINORS?
                                                                        </span>
                                                                      </strong>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <strong>
                                                                    <em>
                                                                      In Short:
                                                                    </em>
                                                                  </strong>
                                                                  <em>
                                                                    &nbsp;We do
                                                                    not
                                                                    knowingly
                                                                    collect data
                                                                    from or
                                                                    market to{" "}
                                                                    <bdt className="block-component" />
                                                                    children
                                                                    under 18
                                                                    years of age
                                                                    <bdt className="else-block" />
                                                                    .
                                                                  </em>
                                                                  <bdt className="block-component" />
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  We do not
                                                                  knowingly
                                                                  solicit data
                                                                  from or market
                                                                  to children
                                                                  under 18 years
                                                                  of age. By
                                                                  using the
                                                                  Services, you
                                                                  represent that
                                                                  you are at
                                                                  least 18 or
                                                                  that you are
                                                                  the parent or
                                                                  guardian of
                                                                  such a minor
                                                                  and consent to
                                                                  such minor
                                                                  dependent’s
                                                                  use of the
                                                                  Services. If
                                                                  we learn that
                                                                  personal
                                                                  information
                                                                  from users
                                                                  less than 18
                                                                  years of age
                                                                  has been
                                                                  collected, we
                                                                  will
                                                                  deactivate the
                                                                  account and
                                                                  take
                                                                  reasonable
                                                                  measures to
                                                                  promptly
                                                                  delete such
                                                                  data from our
                                                                  records. If
                                                                  you become
                                                                  aware of any
                                                                  data we may
                                                                  have collected
                                                                  from children
                                                                  under age 18,
                                                                  please contact
                                                                  us at{" "}
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <bdt className="block-component" />
                                                                      <bdt className="question">
                                                                        tanwish.life@gmail.com
                                                                      </bdt>
                                                                      <bdt className="else-block" />
                                                                    </span>
                                                                  </span>
                                                                  .
                                                                </span>
                                                                <span data-custom-class="body_text">
                                                                  <bdt className="else-block">
                                                                    <bdt className="block-component" />
                                                                  </bdt>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            id="privacyrights"
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(127, 127, 127)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      id="control"
                                                                      style={{
                                                                        color:
                                                                          "rgb(0, 0, 0)"
                                                                      }}
                                                                    >
                                                                      <strong>
                                                                        <span data-custom-class="heading_1">
                                                                          9.
                                                                          WHAT
                                                                          ARE
                                                                          YOUR
                                                                          PRIVACY
                                                                          RIGHTS?
                                                                        </span>
                                                                      </strong>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <strong>
                                                                    <em>
                                                                      In Short:
                                                                    </em>
                                                                  </strong>
                                                                  <em>
                                                                    &nbsp;
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <em>
                                                                            <bdt className="block-component" />
                                                                          </em>
                                                                        </span>
                                                                      </span>
                                                                      &nbsp;
                                                                    </span>
                                                                    You may
                                                                    review,
                                                                    change, or
                                                                    terminate
                                                                    your account
                                                                    at any time.
                                                                  </em>
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15
                                                                      }}
                                                                    >
                                                                      <bdt className="block-component">
                                                                        <bdt className="block-component" />
                                                                      </bdt>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            id="withdrawconsent"
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <strong>
                                                                    <u>
                                                                      Withdrawing
                                                                      your
                                                                      consent:
                                                                    </u>
                                                                  </strong>{" "}
                                                                  If we are
                                                                  relying on
                                                                  your consent
                                                                  to process
                                                                  your personal
                                                                  information,
                                                                  <bdt className="block-component" />{" "}
                                                                  which may be
                                                                  express and/or
                                                                  implied
                                                                  consent
                                                                  depending on
                                                                  the applicable
                                                                  law,
                                                                  <bdt className="statement-end-if-in-editor" />{" "}
                                                                  you have the
                                                                  right to
                                                                  withdraw your
                                                                  consent at any
                                                                  time. You can
                                                                  withdraw your
                                                                  consent at any
                                                                  time by
                                                                  contacting us
                                                                  by using the
                                                                  contact
                                                                  details
                                                                  provided in
                                                                  the section{" "}
                                                                  <bdt className="block-component" />
                                                                  '
                                                                  <bdt className="else-block" />
                                                                </span>
                                                              </span>
                                                            </span>
                                                            <a
                                                              data-custom-class="link"
                                                              href="#contact"
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    HOW CAN YOU
                                                                    CONTACT US
                                                                    ABOUT THIS
                                                                    NOTICE?
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </a>
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <bdt className="block-component" />
                                                                  '
                                                                  <bdt className="else-block" />{" "}
                                                                  below
                                                                  <bdt className="block-component" />
                                                                  .
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                However, please
                                                                note that this
                                                                will not affect
                                                                the lawfulness
                                                                of the
                                                                processing
                                                                before its
                                                                withdrawal nor,
                                                                <bdt className="block-component" />{" "}
                                                                when applicable
                                                                law allows,
                                                                <bdt className="statement-end-if-in-editor" />{" "}
                                                                will it affect
                                                                the processing
                                                                of your personal
                                                                information
                                                                conducted in
                                                                reliance on
                                                                lawful
                                                                processing
                                                                grounds other
                                                                than consent.
                                                                <bdt className="block-component" />
                                                              </span>
                                                            </span>
                                                            <bdt className="block-component">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text" />
                                                              </span>
                                                            </bdt>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="heading_2">
                                                                <strong>
                                                                  Account
                                                                  Information
                                                                </strong>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                If you would at
                                                                any time like to
                                                                review or change
                                                                the information
                                                                in your account
                                                                or terminate
                                                                your account,
                                                                you can:
                                                                <bdt className="forloop-component" />
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <ul>
                                                            <li
                                                              style={{
                                                                lineHeight:
                                                                  "1.5"
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <bdt className="question">
                                                                    Log in to
                                                                    your account
                                                                    settings and
                                                                    update your
                                                                    user
                                                                    account.
                                                                  </bdt>
                                                                </span>
                                                              </span>
                                                            </li>
                                                          </ul>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <bdt className="forloop-component" />
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                Upon your
                                                                request to
                                                                terminate your
                                                                account, we will
                                                                deactivate or
                                                                delete your
                                                                account and
                                                                information from
                                                                our active
                                                                databases.
                                                                However, we may
                                                                retain some
                                                                information in
                                                                our files to
                                                                prevent fraud,
                                                                troubleshoot
                                                                problems, assist
                                                                with any
                                                                investigations,
                                                                enforce our
                                                                legal terms
                                                                and/or comply
                                                                with applicable
                                                                legal
                                                                requirements.
                                                              </span>
                                                            </span>
                                                            <bdt className="statement-end-if-in-editor">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text" />
                                                              </span>
                                                              &nbsp;
                                                            </bdt>
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    "rgb(89, 89, 89)"
                                                                                }}
                                                                              >
                                                                                <bdt className="block-component" />
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <strong>
                                                                    <u>
                                                                      Cookies
                                                                      and
                                                                      similar
                                                                      technologies:
                                                                    </u>
                                                                  </strong>{" "}
                                                                  Most Web
                                                                  browsers are
                                                                  set to accept
                                                                  cookies by
                                                                  default. If
                                                                  you prefer,
                                                                  you can
                                                                  usually choose
                                                                  to set your
                                                                  browser to
                                                                  remove cookies
                                                                  and to reject
                                                                  cookies. If
                                                                  you choose to
                                                                  remove cookies
                                                                  or reject
                                                                  cookies, this
                                                                  could affect
                                                                  certain
                                                                  features or
                                                                  services of
                                                                  our Services.{" "}
                                                                  <bdt className="block-component">
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <bdt className="block-component" />
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  "rgb(89, 89, 89)"
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    color:
                                                                                      "rgb(89, 89, 89)"
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15
                                                                                    }}
                                                                                  >
                                                                                    <span
                                                                                      style={{
                                                                                        color:
                                                                                          "rgb(89, 89, 89)"
                                                                                      }}
                                                                                    >
                                                                                      <bdt className="statement-end-if-in-editor" />
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </bdt>
                                                                </span>
                                                              </span>
                                                            </span>
                                                            <bdt className="block-component">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text" />
                                                              </span>
                                                            </bdt>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                If you have
                                                                questions or
                                                                comments about
                                                                your privacy
                                                                rights, you may
                                                                email us at{" "}
                                                                <bdt className="question">
                                                                  tanwish.life@gmail.com
                                                                </bdt>
                                                                .
                                                              </span>
                                                            </span>
                                                            <bdt className="statement-end-if-in-editor">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text" />
                                                              </span>
                                                            </bdt>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            id="DNT"
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(127, 127, 127)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      id="control"
                                                                      style={{
                                                                        color:
                                                                          "rgb(0, 0, 0)"
                                                                      }}
                                                                    >
                                                                      <strong>
                                                                        <span data-custom-class="heading_1">
                                                                          10.
                                                                          CONTROLS
                                                                          FOR
                                                                          DO-NOT-TRACK
                                                                          FEATURES
                                                                        </span>
                                                                      </strong>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  Most web
                                                                  browsers and
                                                                  some mobile
                                                                  operating
                                                                  systems and
                                                                  mobile
                                                                  applications
                                                                  include a
                                                                  Do-Not-Track (
                                                                  <bdt className="block-component" />
                                                                  'DNT'
                                                                  <bdt className="else-block" />
                                                                  ) feature or
                                                                  setting you
                                                                  can activate
                                                                  to signal your
                                                                  privacy
                                                                  preference not
                                                                  to have data
                                                                  about your
                                                                  online
                                                                  browsing
                                                                  activities
                                                                  monitored and
                                                                  collected. At
                                                                  this stage no
                                                                  uniform
                                                                  technology
                                                                  standard for{" "}
                                                                  <bdt className="block-component" />
                                                                  recognising
                                                                  <bdt className="else-block" />{" "}
                                                                  and
                                                                  implementing
                                                                  DNT signals
                                                                  has been{" "}
                                                                  <bdt className="block-component" />
                                                                  finalised
                                                                  <bdt className="else-block" />
                                                                  . As such, we
                                                                  do not
                                                                  currently
                                                                  respond to DNT
                                                                  browser
                                                                  signals or any
                                                                  other
                                                                  mechanism that
                                                                  automatically
                                                                  communicates
                                                                  your choice
                                                                  not to be
                                                                  tracked
                                                                  online. If a
                                                                  standard for
                                                                  online
                                                                  tracking is
                                                                  adopted that
                                                                  we must follow
                                                                  in the future,
                                                                  we will inform
                                                                  you about that
                                                                  practice in a
                                                                  revised
                                                                  version of
                                                                  this privacy
                                                                  notice.
                                                                  <bdt className="block-component" />
                                                                </span>
                                                              </span>
                                                            </span>
                                                            <bdt className="block-component">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              />
                                                            </bdt>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <br />
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              id="otherlaws"
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <strong>
                                                                <span data-custom-class="heading_1">
                                                                  11. DO OTHER
                                                                  REGIONS HAVE
                                                                  SPECIFIC
                                                                  PRIVACY
                                                                  RIGHTS?
                                                                </span>
                                                              </strong>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <br />
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <em>
                                                                <strong>
                                                                  <span data-custom-class="body_text">
                                                                    In Short:
                                                                  </span>
                                                                </strong>
                                                                <span data-custom-class="body_text">
                                                                  &nbsp;You may
                                                                  have
                                                                  additional
                                                                  rights based
                                                                  on the country
                                                                  you reside in.
                                                                </span>
                                                              </em>
                                                              <bdt className="block-component" />
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <br />
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <bdt className="block-component">
                                                                <span data-custom-class="heading_2" />
                                                              </bdt>
                                                              <span data-custom-class="heading_2">
                                                                <strong>
                                                                  Australia
                                                                </strong>
                                                                <bdt className="statement-end-if-in-editor" />
                                                                <bdt className="block-component" />{" "}
                                                                <strong>
                                                                  and
                                                                </strong>{" "}
                                                                <bdt className="statement-end-if-in-editor" />
                                                                <bdt className="block-component" />
                                                                <strong>
                                                                  New Zealand
                                                                </strong>
                                                              </span>
                                                              <bdt className="statement-end-if-in-editor">
                                                                <span data-custom-class="heading_2" />
                                                              </bdt>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <br />
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                We collect and
                                                                process your
                                                                personal
                                                                information
                                                                under the
                                                                obligations and
                                                                conditions set
                                                                by{" "}
                                                                <bdt className="block-component" />
                                                                Australia's
                                                                Privacy Act 1988
                                                                <bdt className="statement-end-if-in-editor" />
                                                                <bdt className="block-component" />{" "}
                                                                and{" "}
                                                                <bdt className="statement-end-if-in-editor" />
                                                                <bdt className="block-component" />
                                                                New Zealand's
                                                                Privacy Act 2020
                                                                <bdt className="statement-end-if-in-editor" />{" "}
                                                                (Privacy Act).
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <br />
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                This privacy
                                                                notice satisfies
                                                                the notice
                                                                requirements
                                                                defined in
                                                                <bdt className="block-component" />{" "}
                                                                both Privacy
                                                                Acts
                                                                <bdt className="block-component" />
                                                                , in particular:
                                                                what personal
                                                                information we
                                                                collect from
                                                                you, from which
                                                                sources, for
                                                                which purposes,
                                                                and other
                                                                recipients of
                                                                your personal
                                                                information.
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <br />
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                If you do not
                                                                wish to provide
                                                                the personal
                                                                information
                                                                necessary to
                                                                fulfill their
                                                                applicable
                                                                purpose, it may
                                                                affect our
                                                                ability to
                                                                provide our
                                                                services, in
                                                                particular:
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <bdt className="block-component" />
                                                            </span>
                                                          </div>
                                                          <ul>
                                                            <li
                                                              style={{
                                                                lineHeight:
                                                                  "1.5"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  offer you the
                                                                  products or
                                                                  services that
                                                                  you want
                                                                </span>
                                                                <bdt className="statement-end-if-in-editor" />
                                                              </span>
                                                            </li>
                                                          </ul>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <bdt className="block-component" />
                                                            </span>
                                                          </div>
                                                          <ul>
                                                            <li
                                                              style={{
                                                                lineHeight:
                                                                  "1.5"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  respond to or
                                                                  help with your
                                                                  requests
                                                                </span>
                                                                <bdt className="statement-end-if-in-editor" />
                                                              </span>
                                                            </li>
                                                          </ul>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <bdt className="block-component" />
                                                            </span>
                                                          </div>
                                                          <ul>
                                                            <li
                                                              style={{
                                                                lineHeight:
                                                                  "1.5"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  manage your
                                                                  account with
                                                                  us
                                                                </span>
                                                                <bdt className="statement-end-if-in-editor" />
                                                              </span>
                                                            </li>
                                                          </ul>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <bdt className="block-component" />
                                                            </span>
                                                          </div>
                                                          <ul>
                                                            <li
                                                              style={{
                                                                lineHeight:
                                                                  "1.5"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  confirm your
                                                                  identity and
                                                                  protect your
                                                                  account
                                                                </span>
                                                                <bdt className="statement-end-if-in-editor" />
                                                              </span>
                                                            </li>
                                                          </ul>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                At any time, you
                                                                have the right
                                                                to request
                                                                access to or
                                                                correction of
                                                                your personal
                                                                information. You
                                                                can make such a
                                                                request by
                                                                contacting us by
                                                                using the
                                                                contact details
                                                                provided in the
                                                                section{" "}
                                                                <bdt className="block-component" />
                                                                '
                                                                <bdt className="else-block" />
                                                                <a
                                                                  data-custom-class="link"
                                                                  href="#request"
                                                                >
                                                                  <span data-custom-class="link">
                                                                    HOW CAN YOU
                                                                    REVIEW,
                                                                    UPDATE, OR
                                                                    DELETE THE
                                                                    DATA WE
                                                                    COLLECT FROM
                                                                    YOU?
                                                                  </span>
                                                                </a>
                                                                <bdt className="block-component" />
                                                                '
                                                                <bdt className="else-block" />
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <br />
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              I
                                                              <span data-custom-class="body_text">
                                                                f you believe we
                                                                are unlawfully
                                                                processing your
                                                                personal
                                                                information, you
                                                                have the right
                                                                to submit a
                                                                complaint about{" "}
                                                                <bdt className="block-component" />
                                                                a breach of the
                                                                Australian
                                                                Privacy
                                                                Principles to
                                                                the{" "}
                                                                <a
                                                                  data-custom-class="link"
                                                                  href="https://www.oaic.gov.au/privacy/privacy-complaints/lodge-a-privacy-complaint-with-us"
                                                                  rel="noopener noreferrer"
                                                                  target="_blank"
                                                                >
                                                                  <span data-custom-class="link">
                                                                    Office of
                                                                    the
                                                                    Australian
                                                                    Information
                                                                    Commissioner
                                                                  </span>
                                                                </a>
                                                                <bdt className="statement-end-if-in-editor" />
                                                                <bdt className="block-component" />{" "}
                                                                and{" "}
                                                                <bdt className="statement-end-if-in-editor" />
                                                                <bdt className="block-component" />
                                                                a breach of New
                                                                Zealand's
                                                                Privacy
                                                                Principles to
                                                                the{" "}
                                                                <a
                                                                  data-custom-class="link"
                                                                  href="https://www.privacy.org.nz/your-rights/making-a-complaint/"
                                                                  rel="noopener noreferrer"
                                                                  target="_blank"
                                                                >
                                                                  <span data-custom-class="link">
                                                                    Office of
                                                                    New Zealand
                                                                    Privacy
                                                                    Commissioner
                                                                  </span>
                                                                </a>
                                                                <bdt className="statement-end-if-in-editor" />
                                                                .
                                                              </span>
                                                              <bdt className="statement-end-if-in-editor" />
                                                            </span>
                                                            <bdt className="block-component">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              />
                                                            </bdt>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <br />
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <strong>
                                                                <span data-custom-class="heading_2">
                                                                  Republic of
                                                                  South Africa
                                                                </span>
                                                              </strong>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <br />
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                At any time, you
                                                                have the right
                                                                to request
                                                                access to or
                                                                correction of
                                                                your personal
                                                                information. You
                                                                can make such a
                                                                request by
                                                                contacting us by
                                                                using the
                                                                contact details
                                                                provided in the
                                                                section{" "}
                                                                <bdt className="block-component" />
                                                                '
                                                                <bdt className="else-block" />
                                                              </span>
                                                            </span>
                                                            <span data-custom-class="link">
                                                              <a href="#request">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <span data-custom-class="link">
                                                                    HOW CAN YOU
                                                                    REVIEW,
                                                                    UPDATE, OR
                                                                    DELETE THE
                                                                    DATA WE
                                                                    COLLECT FROM
                                                                    YOU?
                                                                  </span>
                                                                </span>
                                                              </a>
                                                            </span>
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <bdt className="block-component" />
                                                                '
                                                                <bdt className="else-block" />
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <br />
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                If you are
                                                                unsatisfied with
                                                                the manner in
                                                                which we address
                                                                any complaint
                                                                with regard to
                                                                our processing
                                                                of personal
                                                                information, you
                                                                can contact the
                                                                office of the
                                                                regulator, the
                                                                details of which
                                                                are:
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <br />
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <a
                                                              data-custom-class="link"
                                                              href="https://inforegulator.org.za/"
                                                              rel="noopener noreferrer"
                                                              target="_blank"
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <span data-custom-class="link">
                                                                    The
                                                                    Information
                                                                    Regulator
                                                                    (South
                                                                    Africa)
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </a>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                General
                                                                enquiries:&nbsp;
                                                              </span>
                                                              <a
                                                                data-custom-class="link"
                                                                href="mailto:enquiries@inforegulator.org.za"
                                                                rel="noopener noreferrer"
                                                                target="_blank"
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <span data-custom-class="link">
                                                                    enquiries@inforegulator.org.za
                                                                  </span>
                                                                </span>
                                                              </a>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                Complaints
                                                                (complete
                                                                POPIA/PAIA form
                                                                5):&nbsp;
                                                              </span>
                                                              <a
                                                                data-custom-class="link"
                                                                href="mailto:PAIAComplaints@inforegulator.org.za"
                                                                rel="noopener noreferrer"
                                                                target="_blank"
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <span data-custom-class="link">
                                                                    PAIAComplaints@inforegulator.org.za
                                                                  </span>
                                                                </span>
                                                              </a>
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                &nbsp;&amp;&nbsp;
                                                              </span>
                                                            </span>
                                                            <a
                                                              data-custom-class="link"
                                                              href="mailto:POPIAComplaints@inforegulator.org.za"
                                                              rel="noopener noreferrer"
                                                              target="_blank"
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <span data-custom-class="link">
                                                                    POPIAComplaints@inforegulator.org.za
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </a>
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <bdt className="statement-end-if-in-editor" />
                                                            </span>
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <bdt className="statement-end-if-in-editor" />
                                                            </span>{" "}
                                                            <bdt className="block-component">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              />
                                                            </bdt>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            id="policyupdates"
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(127, 127, 127)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      id="control"
                                                                      style={{
                                                                        color:
                                                                          "rgb(0, 0, 0)"
                                                                      }}
                                                                    >
                                                                      <strong>
                                                                        <span data-custom-class="heading_1">
                                                                          12. DO
                                                                          WE
                                                                          MAKE
                                                                          UPDATES
                                                                          TO
                                                                          THIS
                                                                          NOTICE?
                                                                        </span>
                                                                      </strong>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <em>
                                                                    <strong>
                                                                      In
                                                                      Short:&nbsp;
                                                                    </strong>
                                                                    Yes, we will
                                                                    update this
                                                                    notice as
                                                                    necessary to
                                                                    stay
                                                                    compliant
                                                                    with
                                                                    relevant
                                                                    laws.
                                                                  </em>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  We may update
                                                                  this privacy
                                                                  notice from
                                                                  time to time.
                                                                  The updated
                                                                  version will
                                                                  be indicated
                                                                  by an updated{" "}
                                                                  <bdt className="block-component" />
                                                                  'Revised'
                                                                  <bdt className="else-block" />{" "}
                                                                  date and the
                                                                  updated
                                                                  version will
                                                                  be effective
                                                                  as soon as it
                                                                  is accessible.
                                                                  If we make
                                                                  material
                                                                  changes to
                                                                  this privacy
                                                                  notice, we may
                                                                  notify you
                                                                  either by
                                                                  prominently
                                                                  posting a
                                                                  notice of such
                                                                  changes or by
                                                                  directly
                                                                  sending you a
                                                                  notification.
                                                                  We encourage
                                                                  you to review
                                                                  this privacy
                                                                  notice
                                                                  frequently to
                                                                  be informed of
                                                                  how we are
                                                                  protecting
                                                                  your
                                                                  information.
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            id="contact"
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(127, 127, 127)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      id="control"
                                                                      style={{
                                                                        color:
                                                                          "rgb(0, 0, 0)"
                                                                      }}
                                                                    >
                                                                      <strong>
                                                                        <span data-custom-class="heading_1">
                                                                          13.
                                                                          HOW
                                                                          CAN
                                                                          YOU
                                                                          CONTACT
                                                                          US
                                                                          ABOUT
                                                                          THIS
                                                                          NOTICE?
                                                                        </span>
                                                                      </strong>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  If you have
                                                                  questions or
                                                                  comments about
                                                                  this notice,
                                                                  you may{" "}
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <bdt className="block-component" />
                                                                      email us
                                                                      at{" "}
                                                                      <bdt className="question">
                                                                        tanwish.life@gmail.com
                                                                      </bdt>
                                                                      <bdt className="statement-end-if-in-editor" />
                                                                    </span>
                                                                  </span>
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        &nbsp;or
                                                                        contact
                                                                        us by
                                                                        post at:
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <bdt className="question">
                                                                            TanwishLIfe
                                                                          </bdt>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <bdt className="block-component" />
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <bdt className="question">
                                                                  206, Utkarsh
                                                                  Umang
                                                                  apartment,
                                                                  Besides mount
                                                                  Carmel girls
                                                                  high
                                                                  school,&nbsp;Ajni&nbsp;sq&nbsp;
                                                                </bdt>
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <bdt className="block-component" />
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <bdt className="question">
                                                                  Nagpur
                                                                </bdt>
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <bdt className="block-component" />
                                                                    ,{" "}
                                                                    <bdt className="question">
                                                                      Maharashtra
                                                                    </bdt>
                                                                    <bdt className="statement-end-if-in-editor" />
                                                                    <bdt className="block-component" />{" "}
                                                                    <bdt className="question">
                                                                      4400022
                                                                    </bdt>
                                                                    <bdt className="statement-end-if-in-editor" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <bdt className="block-component" />
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                <bdt className="question">
                                                                  India
                                                                </bdt>
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <bdt className="statement-end-if-in-editor">
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15
                                                                          }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  "rgb(89, 89, 89)"
                                                                              }}
                                                                            >
                                                                              <bdt className="statement-end-if-in-editor">
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15
                                                                                  }}
                                                                                >
                                                                                  <span data-custom-class="body_text">
                                                                                    <span
                                                                                      style={{
                                                                                        color:
                                                                                          "rgb(89, 89, 89)"
                                                                                      }}
                                                                                    >
                                                                                      <bdt className="statement-end-if-in-editor" />
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </bdt>
                                                                              <bdt className="statement-end-if-in-editor" />
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                        <span data-custom-class="body_text">
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(89, 89, 89)"
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15
                                                                              }}
                                                                            >
                                                                              <bdt className="statement-end-if-in-editor" />
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </bdt>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <bdt className="statement-end-if-in-editor">
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              <bdt className="block-component">
                                                                                <bdt className="block-component" />
                                                                              </bdt>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </bdt>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                <bdt className="block-component">
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  />
                                                                </bdt>
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                        fontSize: 15
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <bdt className="statement-end-if-in-editor">
                                                                            <bdt className="block-component" />
                                                                          </bdt>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            id="request"
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(127, 127, 127)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      id="control"
                                                                      style={{
                                                                        color:
                                                                          "rgb(0, 0, 0)"
                                                                      }}
                                                                    >
                                                                      <strong>
                                                                        <span data-custom-class="heading_1">
                                                                          14.
                                                                          HOW
                                                                          CAN
                                                                          YOU
                                                                          REVIEW,
                                                                          UPDATE,
                                                                          OR
                                                                          DELETE
                                                                          THE
                                                                          DATA
                                                                          WE
                                                                          COLLECT
                                                                          FROM
                                                                          YOU?
                                                                        </span>
                                                                      </strong>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <bdt className="block-component" />
                                                                  You have the
                                                                  right to
                                                                  request access
                                                                  to the
                                                                  personal
                                                                  information we
                                                                  collect from
                                                                  you, change
                                                                  that
                                                                  information,
                                                                  or delete it.{" "}
                                                                  <bdt className="statement-end-if-in-editor" />
                                                                  To request to
                                                                  review,
                                                                  update, or
                                                                  delete your
                                                                  personal
                                                                  information,
                                                                  please{" "}
                                                                  <bdt className="block-component" />
                                                                  fill out and
                                                                  submit a&nbsp;
                                                                </span>
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(48, 48, 241)"
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15
                                                                      }}
                                                                    >
                                                                      <a
                                                                        data-custom-class="link"
                                                                        href="https://app.termly.io/notify/9bc60071-4838-4190-b361-3919e3a0bdda"
                                                                        rel="noopener noreferrer"
                                                                        target="_blank"
                                                                      >
                                                                        data
                                                                        subject
                                                                        access
                                                                        request
                                                                      </a>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                <bdt className="block-component">
                                                                  <span data-custom-class="body_text" />
                                                                </bdt>
                                                              </span>
                                                            </span>
                                                            <span data-custom-class="body_text">
                                                              .
                                                            </span>
                                                          </div>
                                                          <style
                                                            dangerouslySetInnerHTML={{
                                                              __html:
                                                                "\n      ul {\n        list-style-type: square;\n      }\n      ul > li > ul {\n        list-style-type: circle;\n      }\n      ul > li > ul > li > ul {\n        list-style-type: square;\n      }\n      ol li {\n        font-family: Arial ;\n      }\n    "
                                                            }}
                                                          />
                                                        </div>
                                                        <div
                                                          style={{
                                                            color: "#595959",
                                                            fontSize: 14,
                                                            fontFamily: "Arial",
                                                            paddingTop: 16
                                                          }}
                                                        >
                                                          This privacy policy
                                                          was created using
                                                          Termly's{" "}
                                                          <a
                                                            style={{
                                                              color:
                                                                "rgb(48, 48, 241) !important"
                                                            }}
                                                            href="https://termly.io/products/privacy-policy-generator/"
                                                          >
                                                            Privacy Policy
                                                            Generator
                                                          </a>
                                                          .
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</>

  )
}

export default PrivacyPolicyPage
