import React, { useState , useEffect} from "react";
import "./style/header.css";
import Logo from "../../assets/logo-tanvish.png";
import { Link, NavLink, useLocation } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";

const Header = () => {

    const [scrolling, setScrolling] = useState(false);
    const [mobileResponsive, setMobileResponsive] = useState(false);

    const location = useLocation();

    useEffect(() => {
      const handleScroll = () => {
        if (window.scrollY > 50) {
          // Change the background color when scrolling do
          setScrolling(true);
        } else {
          // Reset to the initial background color when scrolling up
          setScrolling(false);
        }
      };
  
      // Attach the event listener to the window's scroll event
      window.addEventListener("scroll", handleScroll);
  
      // Clean up the event listener when the component unmounts
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);

    const isNavLinkActive = (path) => {
        return location.pathname === path;
      };

      const toggleMobileNav = () => {
        setMobileResponsive(!mobileResponsive);
      };
      const closeMobileNav = () => {
        setMobileResponsive(false);
      };   
  return (
    <header className={`header ${scrolling ? 'scrolling' : ""}`}>
      <div className="container navbar-container">
        <div className="site-logo">
          <Link to='/'>
            <img src={Logo} alt="" />
          </Link>
        </div>
        <div className= 'navbar-menu-list' >
          <ul className="navbar-list">
            <li className={`menu-list-item ${isNavLinkActive('/') ?'active' : ''}`}><NavLink to='/'>Home</NavLink></li>
            <li className={`menu-list-item ${isNavLinkActive('/courses') ?'active' : ''}`}><NavLink to='/courses'>Courses</NavLink></li>
            <li className={`menu-list-item ${isNavLinkActive('/about-us') ?'active' : ''}`}><NavLink to='/about-us'>About Us</NavLink></li>
            <li className={`menu-list-item ${isNavLinkActive('/contact-us') ?'active' : ''}`}><NavLink to='/contact-us'>Contact Us</NavLink></li>
          </ul>
        </div>
        <div className={`mobile-navbar-menu ${mobileResponsive ? 'active' : ''} `} onClick={closeMobileNav}>
          <ul className="navbar-list">
            <li className={`menu-list-item ${isNavLinkActive('/') ?'active' : ''}`}><NavLink to='/'>Home</NavLink></li>
            <li className={`menu-list-item ${isNavLinkActive('/courses') ?'active' : ''}`}><NavLink to='/courses'>Courses</NavLink></li>
            <li className={`menu-list-item ${isNavLinkActive('/about-us') ?'active' : ''}`}><NavLink to='/about-us'>About Us</NavLink></li>
            <li className={`menu-list-item ${isNavLinkActive('/contact-us') ?'active' : ''}`}><NavLink to='/contact-us'>Contact Us</NavLink></li>
          </ul>
        </div>
        <div id="mobile-bar" className="mobile-menu-bar">
          <button className="hamburger-mobile" onClick={toggleMobileNav}>
             {mobileResponsive ? <FaTimes /> : <FaBars /> }
          </button>      
        </div>
      </div>
    </header>
  );
};

export default Header;
