import React from "react";
import "./style/aboutCard.css";
import ExpertFaculty from "../../../assets/Expert-facaulty.svg";
import ComprehensiveCurriculum from '../../../assets/comprehsive.svg';
import PracticalTraining from '../../../assets/practical-training.svg';
import Commitment from '../../../assets/commitment.svg';
import Holistic from '../../../assets/holistic.svg';


const AboutCard = () => {
  return (
    <section className="aboutCard d-flex justify-content-canter align-items-center flex-column">
      <div className="container container-about-card-heading">
        <h1>Why Choose Tanwish?</h1>
      </div>
      <div className="about-card-content d-flex justify-content-center align-items-center flex-column">
        <div className="about-card-content-container container">
          <div className="about-card-content-row">
            <div className="card card-about-content-box box-1">
              <img src={ExpertFaculty} alt="..." />
              <div className="card-content-text">
                <h5>Expert Faculty</h5>
                <p>Our institute boasts a team of experienced and renowned Ayurvedic experts who are passionate  about imparting their knowledge to the next generation of healers.</p>
              </div>
            </div>
            <div className="card card-about-content-box">
            <img src={ComprehensiveCurriculum} alt="..." style={{'width': '75%'}}/>
              <div className="card-content-text">
                <h5>Comprehensive<br /> Curriculum</h5>
                <p>Tanwish offers a diverse range of courses, ensuring that our students receive a well-rounded education in various Ayurvedic specialties, enabling them to specialize in their chosen field.</p>
              </div>
            </div>
            <div className="card card-about-content-box">
            <img src={PracticalTraining} alt="..." style={{'width': '43%'}}/>
              <div className="card-content-text">
                <h5>Practical Training</h5>
                <p>We understand the importance of hands-on experience. Our students have access to state-of-the-art facilities and real-world case studies, allowing them to apply their knowledge effectively.</p>
              </div>
            </div>
          </div>
          <div className="about-card-content-row">
            <div className="card card-about-content-box">
            <img src={Commitment} alt="..." />
              <div className="card-content-text">
                <h5>Commitment to<br /> Tradition</h5>
                <p>While embracing modern techniques, we remain deeply rooted in Ayurvedic tradition, preserving the essence of this ancient science.</p>
              </div>
            </div>
            <div className="card card-about-content-box">
            <img src={Holistic} alt="..." style={{'width': '55%', 'margin-top': '55px'}} />
              <div className="card-content-text">
                <h5>Holistic Approach</h5>
                <p>At Tanwish, we emphasize the holistic nature of Ayurveda, training our students to consider the physical, mental, and spiritual aspects of healing.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default AboutCard;
