import React, { useEffect } from "react";
import "./style/heroSection.css";
// import FlowerGlow from "../../../assets/flowe-glow.png";
// import Waveline from "../../../assets/wave-svg-1.svg";
import { Link } from "react-router-dom";
// import SmileWomen from "../../../assets/smiley-woman-holding-book.png";
import Aos from "aos";
import "aos/dist/aos.css";

const HeroSection = () => {
  useEffect(() => {
    Aos.init({
      duration: 2000,
      offset: 100,
    });
  }, []);
  return (
    <section className="heroSectionOfHome">
      <div className="hero-container w-100 d-flex flex-column justify-content-center align-items-center">
        <div className="container content-container d-flex justify-content-center align-items-center flex-column">
          <div
            className="content-text d-flex"
            data-aos="fade-up"
            data-aos-easing="ease"
          >
            <h1>Turn Your Ideas Into Reality </h1>
            <h5>
              Meet the education model that will take you to the next level.
            </h5>
            <Link to="/">
              <button className="btn btn-contact">Contact Us</button>
            </Link>
          </div>
        </div>
          {/* <div className="color-box" data-aos="fade-in" data-aos-duration="10000" data-aos-delay="3000" data-aos-easing="ease">
            <div className="color-box-1"></div>
            <div className="color-box-2"></div>
            <div className="color-box-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="324"
                height="319"
                viewBox="0 0 324 319"
                fill="none"
              >
                <g filter="url(#filter0_f_438_535)">
                  <ellipse
                    cx="162"
                    cy="159.5"
                    rx="82"
                    ry="79.5"
                    fill="#ADA162"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_f_438_535"
                    x="0"
                    y="0"
                    width="324"
                    height="319"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="BackgroundImageFix"
                      result="shape"
                    />
                    <feGaussianBlur
                      stdDeviation="40"
                      result="effect1_foregroundBlur_438_535"
                    />
                  </filter>
                </defs>
              </svg>
            </div>
          </div> */}
        {/* <div className="content-image">
            <img src={SmileWomen} alt="" />
        </div> */}
        {/* <div className="image-flower-glow">
          <img src={FlowerGlow} alt="...." />
        </div> */}
      </div>
      {/* <div className="wave">
        <img src={Waveline} alt="" />
      </div> */}
    </section>
  );
};

export default HeroSection;
