import React from "react";
import "./style/weightGainLandingPage.css";
import { Link } from "react-router-dom";
import BookImage from "../../../assets/WeightGainEbook/Weight-gain-secrets-2-1024x1024.webp";
import ArrowTransparent from "../../../assets/buynowlandingpage/bluearrows1-transparent-1-2.gif";
import LeftArrow from "../../../assets/WeightGainEbook/left-arrow-weight-gain.png";
import RightArrow from "../../../assets/WeightGainEbook/right-arrow-weight-gain.png";
import Ayurvedic from "../../../assets/WeightGainEbook/ayurvedic.svg";
import Stress from "../../../assets/WeightGainEbook/stress.svg";
import Healthy from "../../../assets/buynowlandingpage/lifestyle.svg";
import LifestyleAyureda from "../../../assets/WeightGainEbook/lifestyle-ayurvedic.svg";
import { FaCheck, FaUnlock } from "react-icons/fa";
import Testimonial1 from "../../../assets/WeightGainEbook/feedback-1.jpg";
import Testimonial2 from "../../../assets/WeightGainEbook/feedback-2.jpg";
import Testimonial3 from "../../../assets/WeightGainEbook/feedback-3.jpg";
import { Helmet } from "react-helmet";

const WeightGainLandingPage = () => {
  return (
    <>
    <Helmet>
     {/* Meta Pixel Code */}
<script>
  {`
   !function(f,b,e,v,n,t,s)
   {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
   n.callMethod.apply(n,arguments):n.queue.push(arguments)};
   if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
   n.queue=[];t=b.createElement(e);t.async=!0;
   t.src=v;s=b.getElementsByTagName(e)[0];
   s.parentNode.insertBefore(t,s)}(window, document,'script',
   'https://connect.facebook.net/en_US/fbevents.js');
   fbq('init', '335594925825705');
   fbq('track', 'ViewContent');
  `}
</script>
<noscript>
  {`
     <img height="1" width="1" style="display:none"
     src="https://www.facebook.com/tr?id=335594925825705&ev=PageView&noscript=1"
     />
  `}
</noscript>
{/* End Meta Pixel Code */}
</Helmet>
      <section className="weight-gain-main-page">
        <div className="weight-gain-line-up"></div>
        <div className="weight-gain-main-container">
          <div className="weight-gain-heading-container">
            <h1>Grab Your "Weight Gain Secrets EBook"</h1>
            <h2>
              And Secure Your Roadmap To Gaining Weight Naturally Through
              Ayurveda!
            </h2>
          </div>
          <div className="weight-gain-book-content d-flex justify-content-center align-items-center">
            <div className="weight-gain-book-image-column">
              <img src={BookImage} alt="BookImage" />
            </div>
            <div className="weight-gain-book-animation-column">
              <img src={ArrowTransparent} alt="BookImage" />
            </div>
            <div className="weight-gain-book-content-column">
              <h3> Tired of Weight Gain Frustration?</h3>
              <p>
              But There's Good News. This Comprehensive Ayurvedic Weight Gain Guide Is Designed To Provide You Effective Solutions. With A Personalized Ayurvedic Approach, Expert Guidance, And Proven Ayurvedic Strategies, You Can Finally Overcome the Challenges, Achieve Your Weight Gain Goals, and Enjoy a Healthier, More Confident Life. Say Goodbye to Frustration and Hello to Success!
              </p>
              <Link className="weight-gain-access-button-link">
                <button className="btn btn-weight-gain-get-access">
                  Get Access Now At Just Rs19!
                </button>
              </Link>
            </div>
          </div>
          <div className="weight-gain-click-here-button-container">
            <div className="click-here-left-arrow">
              <img src={LeftArrow} alt="..." />
            </div>
            <div className="button btn-click-here">
              <Link className="button-click-here-link">
                <button className="btn btn-click-here-button">
                "Click Here to Get Your Ebook Now" <br />
                  <span className="btn-click-here-second-text">
                  At Just Rs.19/-
                  </span>
                </button>
              </Link>
            </div>
            <div className="click-here-right-arrow">
              <img src={RightArrow} alt="..." />
            </div>
          </div>
        </div>
        <div className="weight-gain-card-section">
        <div className="weight-gain-book-card-container">
            <div className="weight-gain-book-card-container-heading">
              <h2 className="heading-1">With This E-Book</h2>
              <h2 className="heading-2">You Will Get</h2>
            </div>
            <div className="weight-gain-card-container-content">
              <div className="card card-container-content-box">
                <div className="card-container-box-header">
                  <div className="card-container-image-link">
                    <img src={Ayurvedic} alt="..." />
                  </div>
                  <h2 className="card-heading">Ayurvedic Principles For Weight Gain</h2>
                </div>
                <div className="card-container-box-content">
                  <p>
                  You will be getting a holistic approach to achieving your weight gain goals. You'll learn how to balance your dosha, optimize digestion, and select nourishing foods aligned with your unique body type.
                  </p>
                </div>
              </div>
              <div className="card card-container-content-box">
                <div className="card-container-box-header">
                  <div className="card-container-image-link">
                    <img src={Healthy} alt="..." />
                  </div>
                  <h2 className="card-heading">Healthy Digestion Mastery</h2>
                </div>
                <div className="card-container-box-content">
                  <p>
                  This ebook empowers you with Ayurvedic insights to nurture and fortify your digestive system. You'll gain a deep understanding of how to enhance nutrient absorption, reduce digestive discomfort, and ensure that your body efficiently utilizes the foods you consume, making it a principal of your successful weight gain journey.
                  </p>
                </div>
              </div>
              <div className="card card-container-content-box">
                <div className="card-container-box-header">
                  <div className="card-container-image-link">
                    <img src={Stress} alt="..." />
                  </div>
                  <h2 className="card-heading">
                  Stress Reduction Strategies
                  </h2>
                </div>
                <div className="card-container-box-content">
                  <p>
                  You'll discover effective techniques to create calm in your life. These methods provide a vital foundation for your weight gain journey, helping you manage stress, improve sleep, and ensure a balanced mindset. By embracing these strategies, you'll optimize your overall well-being and enhance your chances of successful weight gain
                  </p>
                </div>
              </div>
              <div className="card card-container-content-box">
                <div className="card-container-box-header">
                  <div className="card-container-image-link">
                    <img src={LifestyleAyureda} alt="..." />
                  </div>
                  <h2 className="card-heading">Ayurvedic Lifestyle Insights</h2>
                </div>
                <div className="card-container-box-content">
                  <p>
                  Get practical guidance on integrating Ayurvedic principles, enhancing your holistic wellness, and aligning your lifestyle with your weight gain goals. By embracing these insights, you'll transform your daily routines into pathways to well-being and sustainable weight gain success.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="weight-gain-list-section">
        <div className="weight-gain-list-container container">
            <div className="weight-gain-list-container-heading">
              <h2 className="weight-gain-list-heading">
              Download Your Ultimate Guide And Start Your Transformational Journey Towards Wellness.
              </h2>
            </div>
            <div className="weight-gain-list-content-box">
              <ul className="weight-gain-list-group">
                <li className="weight-gain-list-group-item">
                  <span className="weight-gain-list-group-icon">
                    <FaCheck />
                  </span>
                  <span className="weight-gain-list-group-text">
                  Discover a holistic approach to achieving your weight gain goals.
                  </span>
                </li>
                <li className="weight-gain-list-group-item">
                  <span className="weight-gain-list-group-icon">
                    <FaCheck />
                  </span>
                  <span className="weight-gain-list-group-text">
                  Gain insights into your body's inherent characteristics and tendencies.
                  </span>
                </li>
                <li className="weight-gain-list-group-item">
                  <span className="weight-gain-list-group-icon">
                    <FaCheck />
                  </span>
                  <span className="weight-gain-list-group-text">
                  Explore Ayurvedic principles that optimize digestion for weight gain.
                  </span>
                </li>
                <li className="weight-gain-list-group-item">
                  <span className="weight-gain-list-group-icon">
                    <FaCheck />
                  </span>
                  <span className="weight-gain-list-group-text">
                  Access a comprehensive guide on selecting foods that support weight gain.
                  </span>
                </li>
                <li className="weight-gain-list-group-item">
                  <span className="weight-gain-list-group-icon">
                    <FaCheck />
                  </span>
                  <span className="weight-gain-list-group-text">
                  Learn strategies to maintain a positive mindset and build a connection between emotions and physical health.
                  </span>
                </li>
                <li className="weight-gain-list-group-item">
                  <span className="weight-gain-list-group-icon">
                    <FaCheck />
                  </span>
                  <span className="weight-gain-list-group-text">
                  Implement actionable Ayurvedic solutions to relieve PCOD discomfort
                  </span>
                </li>
                <li className="weight-gain-list-group-item">
                  <span className="weight-gain-list-group-icon">
                    <FaCheck />
                  </span>
                  <span className="weight-gain-list-group-text">
                  Discover Ayurvedic exercise and yoga routines designed for your muscle growth.
                  </span>
                </li>
                <li className="weight-gain-list-group-item">
                  <span className="weight-gain-list-group-icon">
                    <FaCheck />
                  </span>
                  <span className="weight-gain-list-group-text">
                  Unlock the secrets of quality sleep and stress reduction for weight gain.
                  </span>
                </li>
                <li className="weight-gain-list-group-item">
                  <span className="weight-gain-list-group-icon">
                    <FaCheck />
                  </span>
                  <span className="weight-gain-list-group-text">
                  Learn How to Maintain holistic wellness even after reaching your weight gain goals.
                  </span>
                </li>
              </ul>
            </div>
            <div className="weight-gain-list-footer-content">
              <h2 className="weight-gain-list-footer-text">And Much More...</h2>
              <div className="weight-gain-list-footer-button">
                <Link className="weight-gain-list-footer-link">
                  <button className="btn btn-weight-gain-list-footer">
                  "Click Here to Get Your Ebook Now"<br />{" "}
                    <span className="btn-weight-gain-list-footer-second-text">
                    At Just Rs.19/-
                    </span>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="weight-gain-testimonial-section">
        <div className="weight-gain-container-testimonial">
            <div className="weight-gain-container-testimonial-heading">
              <h2>Have A Look What Others Have To Say</h2>
            </div>
            <div className="weight-gain-testimonial-image-content">
              <div className="testimonial-image">
                <img
                  src={Testimonial1}
                  alt="Testimonial1"
                  className="testimonial-image-review"
                />
              </div>
              <div className="testimonial-image">
                <img
                  src={Testimonial2}
                  alt="Testimonial2"
                  className="testimonial-image-review"
                />
              </div>
              <div className="testimonial-image">
                <img
                  src={Testimonial3}
                  alt="Testimonial3"
                  className="testimonial-image-review"
                />
              </div>
            </div>
            <div className="weight-gain-book-image">
              <img src={BookImage} alt="BookImage" />
            </div>
            <div className="weight-gain-testimonial-button">
              <Link className="weight-gain-testimonial-button-link">
                <button className="btn btn-weight-gain-testimonial-button">
                "Click Here to Get Your Ebook Now" <br />
                  <span className="btn-weight-gain-testimonial-button-second-text">
                  At Just Rs.19/-
                  </span>
                </button>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <footer className="weight-gain-footer">
      <div className="weight-gain-footer-container container">
          <div className="weight-gain-price-column">
            <div className="price-content">
              <h2 className="price">
                <span className="discount-price">₹ 19</span>
                <span className="regular-price">₹ 499</span>
              </h2>
            </div>
            <div className="weight-gain-get-instant-access-content">
              <FaUnlock />
              <span className="weight-gain-get-instant-access-text">
                Get Instant Access Right Now
              </span>
            </div>
          </div>
          <div className="weight-gain-buy-now-button-column">
            <Link className="weight-gain-buy-now-btn-link">
              <button className="btn btn-weight-gain-buy-now-button">Buy Now</button>
            </Link>
          </div>
        </div>
      </footer>
    </>
  );
};

export default WeightGainLandingPage;
