// import logo from './logo.svg';
// import { useEffect } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Courses from './pages/Courses';
import About from './pages/About';
import Contact from './pages/Contact';
// import Header from './components/header/Header';
// import Footer from './components/footer/Footer';
import PrivacyPolicy from './pages/PrivacyPolicy';
import BuyNowLandingPage from './components/landingpage/ebook-lpv2/BuyNowLandingPage';
import WeightGainLandingPage from './components/landingpage/weight-gain-ebook/WeightGainLandingPage';
import ThankYouEbook from './components/landingpage/thankyouPage/thankyouPageForEbook/ThankYouEbook';
import ThankYouWeightGain from './components/landingpage/thankyouPage/thankYouWeightGain/ThankYouWeightGain';

function App() {
  return (
     <BrowserRouter>
      {/* <Header /> */}
       <Routes>
        <Route path='/' element={ <Home /> }/>
        <Route path='/courses' element={ <Courses /> }/>
        <Route path='/about-us' element={ <About /> }/>
        <Route path='/contact-us' element={ <Contact /> }/>
        <Route path='/privacy-policy' element={ <PrivacyPolicy /> }  />
        <Route path='/tanuja-mam-ebook-lp-v2' element={ <BuyNowLandingPage /> } />
        <Route path='/tanuja-mam-weight-gain-ebook-lp-v2' element={ <WeightGainLandingPage /> }/>
        <Route path='/tanuja-mam-ebook-lp-v2/thank-you' element={ <ThankYouEbook
         /> }/>
         <Route path='/tanuja-mam-weight-gain-ebook-lp-v2/thank-you' element={ <ThankYouWeightGain /> }/>

       </Routes>
       {/* <Footer /> */}
     </BrowserRouter>
  );
}

export default App;
