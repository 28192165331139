import React from 'react';
import './style/middleHeading.css';
import { Link } from 'react-router-dom';

const MiddleHeading = () => {
  return (
    <section className='middleHeading d-flex justify-content-center align-items-center'>
        <div className="container middle-heading-container d-flex justify-content-center align-items-center flex-column">
           <div className="middle-heading-content d-flex flex-column justify-content-center align-items-center">
            <h1>If you want to shape your future in Ayurved specialities , This place is for you .</h1>
            <Link><button className='btn btn-contact'>Contact Us</button></Link>
           </div>
        </div>
    </section>
  )
}

export default MiddleHeading;
