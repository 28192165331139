import React, { useEffect } from 'react'
import HeroSection from '../components/about/hero-section/HeroSection'
import TextSection from '../components/about/text-section/TextSection'
import AboutCard from '../components/about/about-card/AboutCard'
import LastSection from '../components/about/last-section/LastSection'
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'

const About = () => {
  useEffect (()=>{
    window.scrollTo(0,0);
  }, []);
  return (
   <>
    <Header />
    <HeroSection />
    <TextSection />
    <AboutCard />
    <LastSection />
    <Footer />
   </>
  )
}

export default About
