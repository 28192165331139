import React, { useEffect } from 'react'
import HeroSection from '../components/home/hero-section/HeroSection';
import Courses from '../components/home/courses/Courses';
import MiddleHeading from '../components/home/middle-heading/MiddleHeading';
import WhyChooseUs from '../components/home/why-choose-us/WhyChooseUs';
import FacilitiesAndService from '../components/home/facilities-and-service/FacilitiesAndService';
import ContactForm from '../components/home/contact-form/ContactForm';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';

const Home = () => {
  useEffect (()=>{
    window.scrollTo(0,0);
  }, [])
  return (
   <>
     <Header />
     <HeroSection />
     <Courses />
     <MiddleHeading />
     <WhyChooseUs />
     <FacilitiesAndService />
     <ContactForm />
     <Footer />
   </>
  )
}

export default Home
