import React from "react";
import "./style/lastSection.css";
import MentorImage from "../../../assets/mentor-image.png";
// import BackgroundImage from "../../../assets/background-footer-image.svg";

const LastSection = () => {
  return (
    <section className="lastSection d-flex flex-column justify-content-center align-items-center">
      <div className="container container-join-us-section d-flex flex-column justify-content-center align-items-center">
        <div className="join-us-container-content">
          <h1>Join Us:</h1>
          <p className="paragraph-1">
            Whether you are a passionate Ayurvedic enthusiast or an experienced
            practitioner looking to enhance your skills, Tanwish Institute of
            Health and Wellness welcomes you to embark on a journey of learning
            and transformation.
          </p>
          <p className="paragraph-2">
            Join us in preserving the wisdom of Ayurveda while shaping its
            future. Together, we can make a difference in the world of wellness.
          </p>
        </div>
      </div>
      <div className="about-mentor d-flex flex-column justify-content-center align-items-center">
        <div className="container-about-mentor container d-flex flex-column justify-content-start">
          <div className="heading-of-mentor">
            <h3>Meet The Mentor</h3>
            <div className="underline"></div>
          </div>
        </div>
        <div className="conatiner-about-mentor-content container d-flex justify-content-center">
          <div className="about-mentor-image">
            <img src={MentorImage} alt="MentorImage" />
          </div>
          <div className="about-mentor-content">
            <h1>Dr. Tanuja Gohane</h1>
            <p className="text-1">
              Founder of Tanwish Institute of Health and Wellness
            </p>
            <p className="paragraph">
              Dr. Tanuja Gohane is a visionary in the world of Ayurvedic
              medicine and education. With a deep-rooted passion for Ayurveda
              and a profound commitment to spreading its wisdom, she is the
              driving force behind the Tanwish Institute of Health and Wellness.
            </p>
            <ul className="content-list">
              <li className="list-items">
                <h5>A Journey of Dedication</h5>
                <p>
                  Dr. Tanuja's journey into the realm of Ayurveda began with an
                  insatiable curiosity and a desire to explore the holistic
                  healing principles of this ancient science. Her unwavering
                  dedication to understanding Ayurveda led her on a path of
                  rigorous study and practical experience.
                </p>
              </li>
              <li className="list-items">
                <h5>Educational Excellence</h5>
                <p>
                  As a distinguished Ayurvedic scholar and practitioner, Dr.
                  Tanuja has earned recognition for her expertise in various
                  Ayurvedic specialties. Her extensive knowledge and dedication
                  to quality education have made her a respected figure in the
                  field.
                </p>
              </li>
              <li className="list-items">
                <h5>Founding Tanwish Institute</h5>
                <p>
                  Driven by her vision to impart authentic Ayurvedic education
                  and elevate the standards of Ayurveda worldwide, Dr. Tanuja
                  founded the Tanwish Institute of Health and Wellness. Her
                  mission is to provide a nurturing and comprehensive learning
                  environment where students can gain the skills and wisdom
                  needed to become successful Ayurvedic doctors.
                </p>
              </li>
              <li className="list-items">
                <h5>Guiding the Next Generation</h5>
                <p>
                  Dr. Tanuja's role as the founder of Tanwish goes beyond
                  leadership; she is a mentor, guide, and inspiration to all who
                  pass through the institute's doors. Her commitment to
                  preserving Ayurvedic tradition while embracing modern
                  approaches sets the institute's philosophy.
                </p>
              </li>
              <li className="list-items">
                <h5>A Legacy of Healing</h5>
                <p>
                  Under Dr. Tanuja's visionary leadership, Tanwish Institute of
                  Health and Wellness continues to shine as a beacon of
                  excellence in Ayurvedic education. Her unwavering dedication
                  to the well-being of individuals and communities through
                  Ayurveda is a testament to her enduring legacy.
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="quate-text-content d-flex flex-column justify-content-center align-items-center">
            <div className="quate-text">
            <h4>Dr. Tanuja Gohane's life's work is a testament to the transformative power of Ayurveda, and her commitment to shaping the future of Ayurvedic medicine is an inspiration to all who aspire to follow in her footsteps.</h4>
          </div>   
        </div>
      </div>
      {/* <div className="image-background">
        <img src={BackgroundImage} alt="" />
      </div> */}
    </section>
  );
};

export default LastSection;
