import React, { useEffect } from 'react'
import ContactMain from '../components/contact/ContactMain';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';

const Contact = () => {
  useEffect (()=>{
    window.scrollTo(0,0);
  }, []);
  return (
    <>
     <Header />
     <ContactMain />
     <Footer />
    </>
  )
}

export default Contact
